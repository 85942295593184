import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Translate, TranslateFunction } from "react-localize-redux";
import { Theme } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { SimpleInput } from "./SimpleInput";
import { BwtButton, BwtButtons } from "./BwtButton";
import { GenderSelection } from "./GenderSelection";
import { getPostcodeRegex } from "../utils/regexp";
import { SupportedCountyCodes } from "../utils/supportedCountries";
import { SupportedLanguageCodes } from "../utils/supportedLanguages";
import { updateProfile, ProfileDto, refreshAntiforgery, validateAdacNumber } from "../utils/accountAPI";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../Providers/authProvider";
import { BwtCountrySelection } from "./BwtCountrySelection";
import { BwtLanguageSelection } from "./BwtLanguageSelection";
import QuestionMark from "../resources/HelpCircle.svg"
import { Overlay } from "./Overlay"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      fontSize: "18px",
      textAlign: "left",
      fontWeight: "bold",
      lineHeight: "1.67",
      color: "var(--col-navy-500)",
    },
    body: {
      display: "flex",
      justifyContent: "center",
    },
    container: {
      paddingTop: theme.spacing(5),
      padding: theme.spacing(5),
      [theme.breakpoints.only("xs")]: {
        paddingTop: theme.spacing(4),
        paddingBottom: "12px",
        width: "100%",
      },
    },
    infoMsg: {
      fontSize: "14px",
      lineHeight: "1.29",
      letterSpacing: "0.35px",
      color: "var(--col-navy-500)",
    },
    textContainer: {
      paddingBottom: "15px",
    },
    actionContainer: {
      paddingTop: "38px",
      width: theme.spacing(40),
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    formControl: {
      width: "20vw",
      minWidth: "200px",
      marginBottom: "15px",
      height: "50px",
      maxWidth: "320px",
      display: "flex",
    },
    adacFieldRoot: {
      display: "flex",
      //2px account for the 1px border used on simple input
      width: "calc(100% + 2px)",
      position: "relative"
    },
    adacQuestionMark: {
      top: "8px",
      right: "0",
      position: "absolute",
      backgroundImage: `url(${QuestionMark})`, 
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "25px",
      width: "25px",
      margin: "6px"
    },
  });
});

interface IChangeDetailsProps {
  profile: ProfileDto;
}
interface IChangeDetailsState {
  disableButton: boolean;
}

export const ChangeDetails: React.FC<IChangeDetailsProps> = ({ profile }) => {
  const classes = useStyles();
  const history = useHistory();
  const { setAuthState } = useAuthContext();
  const [hideAdacNumber, setHideAdacNumber] = useState(profile.CountryCode !== "DE");
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [state, setState] = useState<IChangeDetailsState>({
    disableButton: false,
  });
  const {
    handleSubmit,
    control,
    errors,
    register,
    clearError,
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      gender: profile.Gender,
      country: profile.CountryCode,
      lastName: profile.LastName,
      firstName: profile.FirstName,
      prefLanguage: profile.PreferredLanguage,
      adacNumber: profile.AdacNumber === null ? "" : profile.AdacNumber
    },
  });

  const handleGender = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    clearError("gender");
    setValue("gender", value);
  };
  const onCountryChange = (countryCode: string) => {
    if (control.fieldsRef.current.postcode && countryCode) {
      control.fieldsRef.current.postcode.pattern = getPostcodeRegex(
        countryCode
      );
    }
    control.triggerValidation("postcode");
    if(countryCode === "DE" && hideAdacNumber){
      setHideAdacNumber(false)
    }
    if(countryCode !== "DE" && !hideAdacNumber){
      setHideAdacNumber(true)
    }
    return countryCode;
  };

  useEffect(() => {
    register({ name: "gender" }, { required: true });
    setValue("lastName", profile.LastName);
    setValue("firstName", profile.FirstName);
    setValue("phone", profile.PhoneNumber);
    setValue("postcode", profile.PostalCode);
    setValue("address", profile.Street);
    setValue("location", profile.City);
    setValue("country", profile.CountryCode);
    setValue("prefLanguage", profile.PreferredLanguage);
    setValue("adacNumber", profile.AdacNumber === null ? "" : profile.AdacNumber);
  }, [profile, register, setValue]);

  const handleUpdate = (data: Record<string, any>, translate: TranslateFunction) => {
    setState({ disableButton: true });
    profile.Gender = data["gender"];
    profile.FirstName = data["firstName"];
    profile.LastName = data["lastName"];
    profile.CountryCode = data["country"];
    profile.PreferredLanguage = data["prefLanguage"];
    profile.Street = data["address"];
    profile.City = data["location"];
    profile.PhoneNumber = data["phone"];
    profile.PostalCode = data["postcode"];
    profile.AdacNumber = (data["adacNumber"] === "" || !data["adacNumber"]) ? null : data["adacNumber"].padStart(9,'0')

    if(profile.AdacNumber !== null){
      validateAdacNumber(profile.AdacNumber, profile.Email).then((resp: any) => {
        if(resp.data === true){
          updateProfile(profile)
          .then(
            () => {
              history.goBack();
            },
            (rej: any) => {
              if (rej.response.data.errors) {
                handleServerSideValidationError(rej.response.data.errors);
                setState({ disableButton: false });
                return;
              }
              if (rej.response.status === 401) {
                setAuthState({ isAuth: false });
                refreshAntiforgery();
                history.push("/login", { email: profile.Email });
                return;
              }
    
              history.push("/error?code=CD-001");
            }
          )
          .catch((error: any) => {
            history.push("/error?code=CD-002");
          });
        } else {
          const error = resp.data === "NumberAlreadyUsed" ? translate("validation.numberAlreadyUsed").toString() : translate("validation.invalidAdacAccountNumber").toString()
          setState({ disableButton: false });
          setError("adacNumber", "unknown", error)
        }
      })
    } else {
      updateProfile(profile)
      .then(
        () => {
          history.goBack();
        },
        (rej: any) => {
          if (rej.response.data.errors) {
            handleServerSideValidationError(rej.response.data.errors);
            setState({ disableButton: false });
            return;
          }
          if (rej.response.status === 401) {
            setAuthState({ isAuth: false });
            refreshAntiforgery();
            history.push("/login", { email: profile.Email });
            return;
          }

          history.push("/error?code=CD-001");
        }
      )
      .catch((error: any) => {
        history.push("/error?code=CD-002");
      }); 
    }
  };

  const handleServerSideValidationError = (errors: any) => {
    if (errors.PhoneNumber) {
      setError("phone", "serverSide", errors.PhoneNumber[0]);
    }
    if (errors.CountryCode) {
      setError("country", "serverSide", errors.CountryCode[0]);
    }
    if (errors.FirstName) {
      setError("firstName", "serverSide", errors.FirstName[0]);
    }
    if (errors.LastName) {
      setError("lastName", "serverSide", errors.LastName[0]);
    }
    if (errors.City) {
      setError("location", "serverSide", errors.City[0]);
    }
    if (errors.PostalCode) {
      setError("postcode", "serverSide", errors.PostalCode[0]);
    }
    if (errors.PreferredLanguage) {
      setError("prefLanguage", "serverSide", errors.PreferredLanguage[0]);
    }
    if (errors.Street) {
      setError("address", "serverSide", errors.Street[0]);
    }
    if (errors.Gender) {
      setError("gender", "serverSide", errors.Gender[0]);
    }
  };

  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div className={classes.body}>
            <div className={classes.container}>
              <span className={classes.title}>
                {translate("profile.changeContactEmail")}
              </span>
              <form
                autoComplete="on"
                onSubmit={handleSubmit((data) => handleUpdate(data, translate))}
              >
                <div className={classes.actionContainer}>
                  <GenderSelection
                    handleGenderChange={handleGender}
                    errors={errors}
                    value={getValues()["gender"]}
                  ></GenderSelection>
                  <SimpleInput
                    id={"firstName"}
                    type="text"
                    label={translate("register.firstName") as string}
                    control={control}
                    errors={errors}
                    required={true}
                    autocomplete="given-name"
                  ></SimpleInput>
                  <SimpleInput
                    id={"lastName"}
                    type="text"
                    label={translate("register.lastName") as string}
                    control={control}
                    errors={errors}
                    required={true}
                    autocomplete="family-name"
                  ></SimpleInput>
                  <SimpleInput
                    id={"phone"}
                    type="text"
                    label={translate("register.phone") as string}
                    control={control}
                    errors={errors}
                    autocomplete="tel"
                  ></SimpleInput>
                  <SimpleInput
                    id={"address"}
                    type="text"
                    label={translate("register.address") as string}
                    control={control}
                    errors={errors}
                    required={true}
                    autocomplete={"street-address"}
                  ></SimpleInput>
                  <SimpleInput
                    id={"postcode"}
                    type="text"
                    label={translate("register.postcode") as string}
                    control={control}
                    errors={errors}
                    required={true}
                    regexValidation={getPostcodeRegex(
                      getValues()["country"]
                    )}
                    onchange={([selected]) => {
                      selected.target.value = (selected.target
                        .value as string).toUpperCase();
                      return selected;
                    }}
                    autocomplete={"postal-code"}
                  ></SimpleInput>
                  <SimpleInput
                    id={"location"}
                    type="text"
                    label={translate("register.location") as string}
                    control={control}
                    errors={errors}
                    required={true}
                    autocomplete="address-level2"
                  ></SimpleInput>
                  <BwtCountrySelection
                    errors={errors}
                    control={control}
                    onChange={onCountryChange}
                    selections={SupportedCountyCodes}
                    controllerName="country"
                    label={translate("register.country").toString()}
                  ></BwtCountrySelection>
                  <BwtLanguageSelection
                    errors={errors}
                    control={control}
                    selections={SupportedLanguageCodes}
                    controllerName="prefLanguage"
                    label={translate("register.prefLanguage").toString()}
                  ></BwtLanguageSelection>
                  {!hideAdacNumber && <div className={classes.adacFieldRoot}>
                    <SimpleInput
                  id={"adacNumber"}
                  type="text"
                  defaultValue={profile.AdacNumber}
                  label={translate("register.adacNumber") as string}
                  regexValidation={RegExp("^[0-9]{0,9}$")}
                  control={control}
                  errors={errors}
                  short={true}
                ></SimpleInput> 
                <div className={classes.adacQuestionMark} onClick={() => setOverlayOpen(true)}/>
                </div>}
                <Overlay isOpen={overlayOpen} close={() => setOverlayOpen(false)}/>
                </div>
                <BwtButton
                  label={translate("profile.saveChanges").toString()}
                  styleType={BwtButtons.pink}
                  type="submit"
                  disabled={state.disableButton}
                ></BwtButton>
              </form>
            </div>
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
