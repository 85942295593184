import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Theme, makeStyles, createStyles, FormControl, InputAdornment, Button, } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Controller, Control, EventFunction, NestDataObject, FieldError } from "react-hook-form";
import { Translate, TranslateFunction } from "react-localize-redux";
import { RegWithError } from "../utils/regexp";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: theme.spacing(40),
      border: "solid 1px var(--col-navy-500)",
      marginBottom: "25px",
      height: "48px",
      "& .MuiFormLabel-root.Mui-focused": {
        color: "var(--col-navy-500)",
      },
      "& label + .MuiInput-formControl": {
        paddingTop: "16px",
        marginTop: "0px",
        height: "100%",
      },
      "&:last-child": { marginBottom: "10px" },
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    inputFocus: {
      border: "solid 1px",
      borderColor: "var(--col-aidu-500)",
      boxShadow: "0 0 5px 0 var(--col-aidu-500)",
    },
    passwordMask: {
      "& input[type='password' i]": {
        fontWeight: "500",
        fontFamily: "Verdana",
        height: "15px",
      },
      "& input[type='text' i]": {
        height: "15px",
      },
    },
    textFieldRoot: {
      width: "100%",
      height: "100%",
    },
    centeredButton: {
      marginBottom: "14px",
    },
    inputPadding: { padding: "10px 12px " },
    labelControl: {
      transform: "translate(12px, 20px) scale(1)",
    },
    labelShrink: { transform: "translate(12px, 1.2px) scale(0.75)" },
    labelRoot: {
      fontSize: "16px",
      lineHeight: "1.5",
      letterSpacing: "0.4px",
      color: "var(--col-navy-500)",
    },
    errorLabel: {
      color: "var(--col-accent-magnesium-500)",
      fontSize: "12px",
      display: "flex",
    },
    errorInput: {
      boxShadow: "0 0 5px 0 var(--col-accent-magnesium-500)",
      border: "solid 1px var(--col-accent-magnesium-500)",
    },
  })
);
interface MaskedInputProps {
  errors?: NestDataObject<Record<string, any>, FieldError>;
  placeHolder?: string;
  id: string;
  label: string;
  value: string | unknown;
  allowShow?: boolean;
  control?: Control;
  required?: boolean;
  onchange?: EventFunction;
  minLength?: number;
  regexValidation?: RegWithError[];
  showErrorMsg?: boolean;
  serverSideError?: boolean;
  autoFocus?: boolean;
  showErrorBorder?: boolean;
}

export const MaskedInput: React.FC<MaskedInputProps> = ({
  errors,
  id,
  label,
  onchange,
  value,
  allowShow = true,
  control,
  required,
  minLength,
  showErrorMsg = true,
  showErrorBorder = true,
  regexValidation,
  serverSideError,
  autoFocus = false,
}) => {
  const classes = useStyles();

  const [state, setState] = useState({ showValue: false, focused: false });

  const handleClickShowPassword = () => {
    setState({ ...state, showValue: !state.showValue });
  };
  const showError = () => {
    return serverSideError || errors?.[id];
  };
  const errorMsg = (translate: TranslateFunction): string => {
    if (serverSideError) {
      return translate("register.notValidInput").toString();
    }
    if (errors?.[id]) {
      return errors[id].message === ""
        ? translate("validation.required").toString()
        : errors[id].message;
    }
    return translate("validation.required").toString();
  };
  const validateRegex = (data: any) => {
    var res: string[] = [];
    if (regexValidation) {
      for (let i = 0; i < regexValidation.length; i++) {
        if (regexValidation[i].regex.exec(data) === null) {
          res.push(regexValidation[i].errorText);
        }
      }
    }
    if (res[0] != null) {
      return res.join(";");
    }
    return true;
  };
  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div
            className={`${classes.input} ${
              showError() && showErrorBorder ? classes.errorInput : ""
              } ${state.focused ? classes.inputFocus : ""}`}
          >
            <FormControl classes={{ root: classes.textFieldRoot }}>
              <Controller
                defaultValue={value}
                mode={"onChange"}
                autoFocus={autoFocus}
                label={label}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    formControl: classes.labelControl,
                    shrink: classes.labelShrink,
                  },
                }}
                name={id}
                control={control}
                onChange={onchange}
                rules={{
                  required: required,
                  minLength: minLength,
                  validate: validateRegex,
                }}
                as={TextField}
                className={
                  typeof value === "string" && value?.length > 0
                    ? classes.passwordMask
                    : ""
                }
                InputProps={{
                  classes: { input: classes.inputPadding },
                  disableUnderline: true,
                  endAdornment: allowShow ? (
                    <InputAdornment position="end">
                      <Button
                        disableRipple={true}
                        classes={{ text: classes.centeredButton }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {state.showValue ? (
                          <VisibilityOffIcon />
                        ) : (
                            <VisibilityIcon />
                          )}
                      </Button>
                    </InputAdornment>
                  ) : (
                      ""
                    ),
                }}
                onFocusCapture={() => setState({ ...state, focused: true })}
                onBlurCapture={() => setState({ ...state, focused: false })}
                id={id}
                type={state.showValue ? "text" : "password"}
              />
            </FormControl>
            {showErrorMsg && showError() && (
              <span className={classes.errorLabel}>{errorMsg(translate)}</span>
            )}
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
