import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, CircularProgress } from "@material-ui/core";
import { Logo } from "../components/Logo";
import { Translate } from "react-localize-redux";
import { useAuthContext } from "../Providers/authProvider";
import { logout, refreshAntiforgery } from "../utils/accountAPI";
import { useHistory } from "react-router-dom";
import { useUrlQuery } from "../utils/useUrlQuery";
import Iframe from "react-iframe";

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "40px",
    },
    textContainer: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "50px",
    },
    text: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "1.5",
      color: "var(--col-navy-500)",
    },
  })
);
interface LogoutState {
  signoutIframeUrl: string;
}
export const Logout = () => {
  const classes = useStyles();
  const {setAuthState } = useAuthContext();
  const history = useHistory();
  const queryParams = useUrlQuery();
  const [state, setState] = useState<LogoutState>();
  useEffect(() => {
    const logoutId = queryParams.get("logoutId");
    logout(logoutId)
      .then(
        (res: any) => {
          const signoutIframeUrl = res.data.signOutIframeUrl;
          const postLogoutRedirectUri = res.data.postLogoutRedirectUri;
          if (signoutIframeUrl) {
            setState({ signoutIframeUrl: signoutIframeUrl });
            setTimeout(() => {
              if (postLogoutRedirectUri) {
                window.location = postLogoutRedirectUri;
              } else {
                setAuthState({ isAuth: false });
                history.push("/");
              }
            }, 2000);
          } else {
            if (postLogoutRedirectUri) {
              window.location = postLogoutRedirectUri;
            } else {
              setAuthState({ isAuth: false });
              history.push("/");
            }
          }
        },
        (rej: any) => {
          if (rej.response.status === 401) {
            setAuthState({ isAuth: false });
            refreshAntiforgery();
            history.push("/");
            return;
          }
          history.push("/error?code=LO-001");
        }
      )
      .catch((e: any) => {
        history.push("/error?code=LO-002");
      });
    /* eslint-disable-next-line */
  }, []);
  return (
    <React.Fragment>
      <Logo></Logo>
      <Translate>
        {({ translate }) => (
          <div className={classes.textContainer}>
            <div className={classes.text}>
              {translate("logout.weAreLoggingOut")}
            </div>
          </div>
        )}
      </Translate>
      <div className={classes.loading}>
        <CircularProgress />
      </div>
      {state?.signoutIframeUrl && (
        <Iframe
          url={state?.signoutIframeUrl}
          width="0px"
          height="0px"
          id="logoutIframe"
        />
      )}
    </React.Fragment>
  );
};
