import React, { useEffect, useState } from "react";
import { Card, createStyles, Theme, makeStyles } from "@material-ui/core";
import { RegisterInitialStep } from "../components/RegisterInitialStep";
import RegisterDetailStep from "../components/RegisterDetailStep";
import { useRegisterContext } from "../Providers/registerProvider";
import { Logo } from "../components/Logo";
import { AuthRedirect } from "../components/AuthRedirect";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { ErrorCard } from "../components/ErrorCard";
import { sharedStyles } from "../themes/sharedStyles";
import { NotFoundPage } from "./notFound";
import { useUrlQuery } from "../utils/useUrlQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    container: {
      display: "flex",
      flexWrap: "wrap",
      margin: `${theme.spacing(0)} auto`,
      justifyContent: "center",
    },
  })
);

export const Register = () => {
  const classes = useStyles();
  const { setRegisterState, state } = useRegisterContext();
  const returnUrl = useUrlQuery().get("ReturnUrl") ||  localStorage.getItem('returnUrl')|| "/profile";
  const [error, setError] = useState({
    active: false,
    message: "",
    header: "",
  });

  useEffect(() => {
    var stateUpdate = state;
    stateUpdate.returnUrl = returnUrl;
    localStorage.setItem("returnUrl", returnUrl);
    setRegisterState(stateUpdate);
    /* eslint-disable-next-line */
  }, []);

  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Logo></Logo>
      <AuthRedirect>
        <div>
          {error.active && (
            <ErrorCard text={error.message} header={error.header}></ErrorCard>
          )}
          <div className={classes.container}>
            <Card className={classes.card}>
              <Switch>
                <Route
                  exact
                  path={path}
                  component={() => (
                    <RegisterInitialStep
                      setErrorContainer={(active, msg, header) =>
                        setError({ active, message: msg, header: header ?? "" })
                      }
                    />
                  )}
                ></Route>
                <Route
                  path={`${path}/details`}
                  component={() => <RegisterDetailStep />}
                ></Route>
                <Route component={NotFoundPage} />
              </Switch>
            </Card>
          </div>
        </div>
      </AuthRedirect>
    </React.Fragment>
  );
};
