import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Translate } from "react-localize-redux";
import BwtTicked from "./bwtTicked";
import BwtUnticked from "./bwtUnticked";


const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    descriptionContainer: {
        marginTop: "-15px",
        fontSize: "14px",
        width: theme.spacing(40),
        [theme.breakpoints.only("xs")]: {
          width: "100%",
        },
      },
      passwordComplexityLine: {
        display: "flex",
        paddingBottom: "8px",
        alignItems: "center",
      },
      passwordComplexityText: {
        paddingLeft: "10px",
      },
  });
});

interface IPasswordDescriptionProps {
    passErrors:string[] | "empty" | undefined;
    control:any;
  }
export const PasswordDescription: React.FC<IPasswordDescriptionProps> = ({passErrors,control}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
         <div className={classes.descriptionContainer}>
         <div style={{ paddingBottom: "8px", color: "var(--col-navy-500)" }}>
           {translate("register.youPasswordMustContain")}
         </div>
         <div className={classes.passwordComplexityLine}>
           {passErrors?.includes("register.atLeastOneLetter") ||
           passErrors === "empty" ? (
             <BwtUnticked
               color={
                 control.formState.isSubmitted
                   ? "var(--col-accent-magnesium-500)"
                   : "var(--col-navy-500)"
               }
             ></BwtUnticked>
           ) : (
             <BwtTicked></BwtTicked>
           )}
           <div
             style={{
               color:
                 (passErrors?.includes("register.atLeastOneLetter") ||
                   passErrors === "empty") &&
                 control.formState.isSubmitted
                   ? "var(--col-accent-magnesium-500)"
                   : "var(--col-navy-500)",
             }}
             className={classes.passwordComplexityText}
           >
             {translate("register.atLeastOneLetter")}
           </div>
         </div>
         <div className={classes.passwordComplexityLine}>
           {passErrors?.includes("register.atLeastOneNumber") ||
           passErrors === "empty" ? (
             <BwtUnticked
               color={
                 control.formState.isSubmitted
                   ? "var(--col-accent-magnesium-500)"
                   : "var(--col-navy-500)"
               }
             ></BwtUnticked>
           ) : (
             <BwtTicked></BwtTicked>
           )}
           <div
             style={{
               color:
                 (passErrors?.includes("register.atLeastOneNumber") ||
                   passErrors === "empty") &&
                 control.formState.isSubmitted
                   ? "var(--col-accent-magnesium-500)"
                   : "var(--col-navy-500)",
             }}
             className={classes.passwordComplexityText}
           >
             {translate("register.atLeastOneNumber")}
           </div>
         </div>
         <div className={classes.passwordComplexityLine}>
           {passErrors?.includes("register.8orMoreCharacters") ||
           passErrors === "empty" ? (
             <BwtUnticked
               color={
                 control.formState.isSubmitted
                   ? "var(--col-accent-magnesium-500)"
                   : "var(--col-navy-500)"
               }
             ></BwtUnticked>
           ) : (
             <BwtTicked></BwtTicked>
           )}
           <div
             style={{
               color:
                 (passErrors?.includes("register.8orMoreCharacters") ||
                   passErrors === "empty") &&
                 control.formState.isSubmitted
                   ? "var(--col-accent-magnesium-500)"
                   : "var(--col-navy-500)",
             }}
             className={classes.passwordComplexityText}
           >
             {translate("register.8orMoreCharacters")}
           </div>
         </div>
         <div className={classes.passwordComplexityLine}>
           {passErrors?.includes("register.specialCharacter") ||
           passErrors === "empty" ? (
             <BwtUnticked
               color={
                 control.formState.isSubmitted
                   ? "var(--col-accent-magnesium-500)"
                   : "var(--col-navy-500)"
               }
             ></BwtUnticked>
           ) : (
             <BwtTicked></BwtTicked>
           )}
           <div
             style={{
               color:
                 (passErrors?.includes("register.specialCharacter") ||
                   passErrors === "empty") &&
                 control.formState.isSubmitted
                   ? "var(--col-accent-magnesium-500)"
                   : "var(--col-navy-500)",
             }}
             className={classes.passwordComplexityText}
           >
             {translate("register.specialCharacter")}
           </div>
         </div>
       </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
