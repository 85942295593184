import React from "react";

const BwtTicked = () => (
  <svg
    id="controls_toggle_circle_checked"
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <path
      id="Subtraction_9"
      data-name="Subtraction 9"
      d="M8.5,17A8.5,8.5,0,0,1,2.49,2.49a8.493,8.493,0,0,1,12.233.22L7.834,10.048,4.7,8.111a.522.522,0,0,0-.757-.02.568.568,0,0,0-.018.784l3.548,3.857a.534.534,0,0,0,.389.171h.02a.535.535,0,0,0,.394-.2l7.19-9.072A8.5,8.5,0,0,1,8.5,17Z"
      fill="var(--col-aidu-500)"
    />
  </svg>
);

export default BwtTicked;
