import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  CardContent,
  Card,
  CardHeader,
  CardActions,
  Button,
} from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { useHistory } from "react-router-dom";
import { sharedStyles } from "../themes/sharedStyles";
import { BwtButton, BwtButtons } from "./BwtButton";
import { onBackToHomepage } from "../utils/linkToDocs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    container: {
      display: "flex",
      flexWrap: "wrap",
      margin: `${theme.spacing(0)} auto`,
      justifyContent: "center",
    },
    cardHeader: {
      fontSize: "35px",
      fontWeight: "bold",
      lineHeight: "1.67",
      textAlign: "left",
      color: "#5f6062",
      display: "flex",
      justifyContent: "center",
    },
    headerRoot: {
      paddingBottom: "10px",
    },
    centeredContainer: {
      padding: "8px 16px",
      display: "flex",
      justifyContent: "center",
   
    },
    cardButton: {
      marginTop: theme.spacing(1),
      flexGrow: 1,
      borderRadius: "50px",
      height: "50px",
    },
    capitalizeButton: {
      textTransform: "capitalize",
      boxShadow: "none",
      height: "50px",
      width: "320px",
      marginTop: "16px",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
  })
);

export const LinkNotValid: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const onBackToAccount = () => {
    history.push("/");
  };
  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div className={classes.container}>
            <Card className={classes.card}>
              <CardHeader
                classes={{
                  title: classes.cardHeader,
                  root: classes.headerRoot,
                }}
                title={translate("forgotPassword.linkNotValid")}
              />
              <CardContent style={{ padding: "0px" }}>
                <div className={classes.centeredContainer}>
                  <p>{translate("forgotPassword.requestNewPassword")}</p>
                </div>
              </CardContent>
              <CardActions style={{ paddingTop: "4vh", padding: "0px 16px",display:"grid" }}>
                <BwtButton
                  styleType={BwtButtons.pink}
                  type="button"
                  onClick={() => history.push("/forgotPassword")}
                  label={translate("forgotPassword.resendEmail").toString()}
                ></BwtButton>
                <Button
                  style={{ marginLeft: "0px" }}
                  classes={{ root: classes.capitalizeButton }}
                  onClick={() => onBackToHomepage()}
                >
                  {translate("resendEmail.backToHomepage")}
                </Button>
                <Button
                  style={{ marginLeft: "0px" }}
                  classes={{ root: classes.capitalizeButton }}
                  onClick={() => onBackToAccount()}
                >
                  {translate("resendEmail.backToAccount")}
                </Button>
              </CardActions>
            </Card>
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
