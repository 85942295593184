import React, { useEffect } from "react";
import { getProfile, ProfileDto } from "../utils/accountAPI";
import { withLocalize, LocalizeContextProps } from "react-localize-redux";
import { getFirstAvailableLang } from "../utils/localization";
import { useUrlQuery } from "../utils/useUrlQuery";

type AuthState =
  { isAuth: true, profile?: ProfileDto, issue?: 'Profile missing' }
  | { isAuth: false, state?: 'loading' }
  ;

interface AuthContextState {
  authState: AuthState;
  setAuthState: (authState: AuthState) => void;
}

export const AuthContext = React.createContext<AuthContextState>({
  authState: { isAuth: false, state: 'loading' },
  setAuthState: () => { }
});

const AuthProvider: React.FC<LocalizeContextProps> = ({ children, setActiveLanguage, languages }) => {

  const [authState, setState] = React.useState<AuthState>({ isAuth: false, state: 'loading' });

  const langPara = useUrlQuery().get('lang');

  useEffect(() => {
    setState({ isAuth: false, state: 'loading' });
    getProfile().then(
      (profile: any) => {
        if (profile === "Profile missing") {
          setState({ isAuth: true, issue: "Profile missing" })
          return;
        }
        setState({ isAuth: true, profile })
      },
      (rej: any) => {
        setState({ isAuth: false });
      }
    );
  }, []);

  useEffect(() => {
    setActiveLanguage(getFirstAvailableLang(languages, [
      langPara,
      authState.isAuth ? authState?.profile?.PreferredLanguage : null,
      ...(navigator.languages ? navigator.languages : [navigator.language])
    ]));
  }, [setActiveLanguage, languages, langPara, authState]);


  return (
    <AuthContext.Provider value={{ setAuthState: setState, authState }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthContext(): AuthContextState {
  const { authState, setAuthState } = React.useContext(AuthContext);
  return { authState, setAuthState };
}

export default withLocalize(AuthProvider);
