import React from "react";

export default function AvatarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 11.9972C10.869 11.9972 9.90301 11.6063 9.10216 10.8245C8.30131 10.0427 7.90088 9.09981 7.90088 7.99572C7.90088 6.89163 8.30131 5.94966 9.10216 5.16979C9.90301 4.38993 10.869 4 12 4C13.131 4 14.097 4.38993 14.8978 5.16979C15.6987 5.94966 16.0991 6.89163 16.0991 7.99572C16.0991 9.09981 15.6987 10.0427 14.8978 10.8245C14.097 11.6063 13.131 11.9972 12 11.9972ZM6.21865 20C5.60454 20 5.08123 19.7889 4.64874 19.3667C4.21625 18.9445 4 18.4337 4 17.8342V17.1296C4 16.556 4.15194 16.0279 4.45582 15.5454C4.7597 15.0628 5.1648 14.6949 5.67114 14.4418C6.69052 13.9461 7.72811 13.5734 8.78389 13.3237C9.83967 13.074 10.9117 12.9491 12 12.9491C13.0961 12.9491 14.172 13.073 15.2278 13.3209C16.2835 13.5687 17.3173 13.9404 18.3289 14.4361C18.8352 14.6893 19.2403 15.0562 19.5442 15.5368C19.8481 16.0175 20 16.5484 20 17.1296V17.8342C20 18.4337 19.7838 18.9445 19.3513 19.3667C18.9188 19.7889 18.3955 20 17.7814 20H6.21865Z"
        fill={props.color}
      />
    </svg>
  );
}
