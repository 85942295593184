import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle } from "@material-ui/core"
import { Translate } from "react-localize-redux";
import { BwtButton, BwtButtons } from "./BwtButton";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
        textAlign: "center"
    },
    body: {
        textAlign: "center",
        margin: "0 20px"
    },
    buttonContainer: {
      margin: "20px 0",
      width: "100%",
      display: "flex",
      justifyContent: "center"
    },
  });
});
interface OverlayProps {
  isOpen: boolean;
  close: () => void
}
export const Overlay: React.FC<OverlayProps> = ({ isOpen, close }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <Dialog open={isOpen} >
            <DialogTitle className={classes.title}>{translate("register.adacDialogTitle") as string}</DialogTitle>
            <div className={classes.body}>{translate("register.adacDialogBody") as string}</div>
            <div className={classes.buttonContainer}>
              <div style={{ minWidth: "180px"}}>
              <BwtButton
              label={translate("register.adacDialogButton") as string}
              styleType={BwtButtons.pink}
              onClick={close}
              />
              </div>
            </div>
        </Dialog>
        )}
      </Translate>
    </React.Fragment>
  );
};
