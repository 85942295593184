import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Translate } from "react-localize-redux";
import { MiniLogo } from "../components/MiniLogo";
import {
  getProfile,
  ProfileDto,
  refreshAntiforgery,
} from "../utils/accountAPI";
import { Route, useRouteMatch, Switch, useHistory } from "react-router-dom";
import { ProfileBody } from "../components/ProfileBody";
import { ChangePassword } from "../components/ChangePassword";
import { ChangeEmail } from "../components/ChangeEmail";
import { ChangeDetails } from "../components/ChangeDetails";
import { ChangeEmailPreferences } from "../components/ChangeEmailPreferences";
import { AvatarMenu } from "../components/AvatarMenu";
import { CircularProgress } from "@material-ui/core";
import { ResendEmail } from "../components/ResendEmail";
import { NotFoundPage } from "./notFound";
import { AuthRedirect } from "../components/AuthRedirect";

const useStyles = makeStyles(() =>
  createStyles({
    Title: {
      fontSize: "48px",
      fontWeight: "bold",
      letterSpacing: "1.2px",
      textAlign: "center",
      color: "var(--col-navy-500)",
    },
    TitleContainer: {
      display: "flex",
      paddingTop: "80px",
      justifyContent: "center",
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "40px",
    },
  })
);

interface IProfileState extends ProfileDto {
  ApiState: "loading" | "loaded";
  Iframe: string;
}
const profileInit: IProfileState = {
  Email: "",
  FirstName: "",
  LastName: "",
  PostalCode: "",
  PhoneNumber: "",
  CountryCode: "",
  City: "",
  Gender: "",
  PreferredLanguage: "",
  Street: "",
  ApiState: "loading",
  EmailConfirmed: true,
  isPasswordSet: false,
  canContact: false,
  Iframe: "",
  AdacNumber: ""
};

export const ProfilePage = () => {
  const classes = useStyles();
  const [state, setState] = useState<IProfileState>(profileInit);
  const history = useHistory();
  useEffect(() => {
    let isSubscribed = true;
    getProfile().then(
      (res: any) => {
        const profileState: IProfileState = res === 'Profile missing'
          ? { ...profileInit, ApiState: "loaded", Iframe: "" }
          : { ...res, ApiState: "loaded", Iframe: "" };
        return isSubscribed ? setState(profileState) : null;
      },
      (_: any) => (
        isSubscribed
          ? refreshAntiforgery().finally(() => history.push(""))
          : null
      )
    );
    return () => {
      isSubscribed = false;
    };
  }, [history]);
  const { path } = useRouteMatch();

  const setLoadingState = () => {
    setState({ ...state, ApiState: "loading" });
  };
  const setIframe = (url: string) => {
    setState({ ...state, ApiState: "loading", Iframe: `<iframe src="${url}" width="0" height="0"></iframe>` })
  };
  const setEmail = (email: string) => {
    setState({ ...state, Email: email, EmailConfirmed: false });
  };
  const setEmailPreferences = (canContact: boolean) =>{
    setState({...state, canContact: canContact});
  };
  const setEmailConfirmation = (isConfirmed: boolean) => {
    setState({ ...state, EmailConfirmed: isConfirmed });
  };
  const setPasswordSet = () => {
    setState({ ...state, isPasswordSet: true });
  };

  return (
    <React.Fragment>
      <AuthRedirect>
        {state.ApiState === "loading" && (
          <div className={classes.loading}>
            {state.Iframe !== "" && (<div dangerouslySetInnerHTML={{ __html: state.Iframe }} />)}
            <CircularProgress />
          </div>
        )}
        {state.ApiState === "loaded" && (
          <div>
            <div>
              <AvatarMenu setLoadingState={setLoadingState} setIframe={setIframe}></AvatarMenu>
              <MiniLogo></MiniLogo>
            </div>
            <Translate>
              {({ translate }) => (
                <div>
                  <div className={classes.TitleContainer}>
                    <span className={classes.Title}>
                      {translate("profile.myAccount")}
                    </span>
                  </div>
                  <Switch>
                    <Route
                      exact
                      path={path}
                      component={() => <ProfileBody profile={state} />}
                    ></Route>
                    <Route
                      path={`${path}/detailsEdit`}
                      component={() => <ChangeDetails profile={state} />}
                    ></Route>
                    <Route
                      path={`${path}/emailEdit`}
                      component={() => (
                        <ChangeEmail
                          setEmail={setEmail}
                          setEmailConfirmation={setEmailConfirmation}
                        />
                      )}
                    ></Route>
                    <Route
                      path={`${path}/emailPreferencesEdit`}
                      component={() => (
                        <ChangeEmailPreferences
                          canContact={state.canContact}
                          setCanContact={setEmailPreferences}
                          ></ChangeEmailPreferences>
                      )}
                    ></Route>
                    <Route
                      path={`${path}/passEdit`}
                      component={() => (
                        <ChangePassword
                          isPasswordSet={state.isPasswordSet}
                          setPasswordSet={setPasswordSet}
                        />
                      )}
                    ></Route>
                    <Route
                      path={`${path}/resendEmail`}
                      component={() => <ResendEmail email={state.Email} />}
                    ></Route>
                    <Route component={NotFoundPage} />
                  </Switch>
                </div>
              )}
            </Translate>
          </div>
        )}
      </AuthRedirect>
    </React.Fragment>
  );
};
