export const getReturnUrl = (redirecUrl: string | null ) => {

  if (!redirecUrl) {
    return null;
  }
  try {
    const url = new URL(window.location.href + redirecUrl);
    const redirect = url.searchParams.get("redirect_uri");
    if (redirect !== null) {
      var redirectUrl = new URL(redirect);
      return redirectUrl.href;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
  return null;
};

export const isUrlLocal = (url: string) => {
  if(url.startsWith("/")){
    return true;
  }
  try {
    var urlo = new URL(url);
    var host = urlo.host;
    if (host === window.location.host) {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const getUserFrendlyLabelForUrl=(url: string|null) => {
    if(url)
        try {
            const urlObj = new URL(url);
            return urlObj.host;
        
        } catch (error) {
            console.error(error);
            return "Home";
        }
    return "Home";
}