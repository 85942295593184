import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Translate } from "react-localize-redux";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: " 1.5",
      color: "var(--col-navy-500)",
    },
    detailsContainer: {
      padding: "10px 0px",
      color: "var(--col-navy-500)",
      display:"flex"
    },
  });
});
export const Password: React.FC = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div style={{paddingTop:"20px"}}>
            <span className={classes.title}>
              {translate("profile.password")}
            </span>
            <div className={classes.detailsContainer}>
              <span >**************</span>
            </div>
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
