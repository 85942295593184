import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Translate } from "react-localize-redux";
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const useStyles = makeStyles((theme: Theme) =>{
    return createStyles({
        title:{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "1.5",
            color: "var(--col-navy-500)",
        },
        detailsContainer: {
            padding: "10px 0px",
            color: "var(--col-navy-500)",
            display: "flex",
            alignItems: "center"
        },
        infoLine: {
            letterSpacing: "0.4px",
            lineHeight: "1.5",
            paddingLeft: "5px"
        },
        emailIcon:{
            fontSize: "1em"
        }
    });
});

interface NewsletterProps {
    optedIn: boolean;
}

export const Newsletter: React.FC<NewsletterProps> = ({optedIn}) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Translate>
                {({translate}) => (
                    <div style={{ paddingTop: "20px" }}>
                        <span className={classes.title}>
                            {translate("profile.newsletter").toString()}
                        </span>
                        <div className={classes.detailsContainer}>
                            <MailOutlineIcon className={classes.emailIcon}></MailOutlineIcon>
                            <span className={classes.infoLine}>{optedIn ? translate("profile.newsletterOptedIn").toString() : translate("profile.newsletterOpedOut").toString()} </span>
                        </div>
                    </div>
                )}
            </Translate>
        </React.Fragment>
    );
}