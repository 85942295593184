import React, { useState } from "react";
import { Logo } from "../components/Logo";
import { ForgottenPasswordBody } from "../components/ForgotPasswordBody";
import { EmailSent } from "../components/EmailSent";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import {ConfirmPasswordReset} from "../components/ConfirmPasswordReset"
import { NotFoundPage } from "./notFound";

export interface ForgotPassState {
  emailSent: boolean;
}
export const ForgotPassPage: React.FC = () => {
  const [state, setState] = useState<ForgotPassState>({ emailSent: false });
  const setEmailSent = () => {
    setState({ emailSent: true });
  };
  const { path } = useRouteMatch();
  const renderBody = () => {
    switch (state?.emailSent) {
      case false:
        return (
          <ForgottenPasswordBody
            setEmailSent={setEmailSent}
          ></ForgottenPasswordBody>
        );
      case true:
        return <EmailSent></EmailSent>;
      default:
        return (
          <ForgottenPasswordBody
            setEmailSent={setEmailSent}
          ></ForgottenPasswordBody>
        );
    }
  };
  return (
    <React.Fragment>
      <Logo></Logo>
      <Switch>
        <Route
          exact
          path={path}
          component={()=>renderBody()}
        ></Route>
        <Route
          path={`${path}/confirm`}
          component={() => <ConfirmPasswordReset  />}
        ></Route>
       <Route component={NotFoundPage} />
      </Switch>
      
    </React.Fragment>
  );
};
