import React from "react";
import { makeStyles, createStyles, Theme, Button } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWhite: {
      marginTop: theme.spacing(1),
      flexGrow: 1,
      borderRadius: "50px",
      backgroundColor: "var(--col-grey-0)",
      boxShadow: "0px 2px 3px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 0.8px var(--col-navy-500)",
    },
    buttonPink: {
      marginTop: theme.spacing(1),
      flexGrow: 1,
      borderRadius: "50px",
      height: "50px",
      backgroundColor: "var(--col-aidu-500)",
      "& $buttonLabel": {
        color: "var(--col-grey-0)",
      },
    },
    buttonLargeWhite: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      flexGrow: 1,
      borderRadius: "50px",
      border: "solid 0.8px var(--col-aidu-500)",
      height: "50px",
      backgroundColor: "var(--col-grey-0)",
      "& $buttonLabel": {
        color: "var(--col-aidu-500)",
      },
    },
    buttonRootLarge: {
      boxShadow: "none",
      textTransform: "unset",
      width:theme.spacing(40),
      height: "50px",
      "&:hover": {
        boxShadow: "0px 2px 3px 0 rgba(0, 0, 0, 0.1)",
        border: "solid 0.8px var(--col-aidu-500)",
        backgroundColor: "white",
        "& span": {
          color: "var(--col-aidu-500)",
        },
      },
      [theme.breakpoints.only("xs")]: {
        width:"100%",
      },
    },
    buttonRoot: {
      textTransform: "unset",
      width: "260px",
      "&:hover": {
        boxShadow: "0px 2px 3px 0 rgba(0, 0, 0, 0.1)",
        border: "solid 0.8px var(--col-aidu-500)",
        backgroundColor: "white",
        "& span": {
          color: "var(--col-aidu-500)",
        },
      },
    },
    buttonRootPink: {
      boxShadow: "none",
      textTransform: "unset",
      width: theme.spacing(40),
      height: "50px",
      "&:hover": {
        border : "hidden",
        backgroundColor: "var(--col-aidu-500)",
          "& span": {
            color: "var(--col-grey-0)",
          },
        },
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
    },
    buttonLabel: {
      lineHeight: "1.5",
      fontSize: "16px",
      fontWeight: "normal",
      color: "var(--col-navy-500)"
    },
  })
);
export enum BwtButtons {
  white,
  pink,
  largewhite,
}
interface BwtButtonProps {
  label: string;
  disabled?:boolean;
  type?: "button" | "reset" | "submit" | undefined;
  onClick?: () => void;
  styleType?: BwtButtons;
}

export const BwtButton: React.FC<BwtButtonProps> = ({
  label,
  styleType = BwtButtons.white,
  onClick,
  type,
  disabled
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {styleType === BwtButtons.white && (
        <Button
          type={type}
          variant="contained"
          size="small"
          color="default"
          className={classes.buttonWhite}
          classes={{
            root: classes.buttonRoot,
            label: classes.buttonLabel,
          }}
          disabled={disabled}
          onClick={onClick}
        >
          {label}
        </Button>
      )}
      {styleType === BwtButtons.pink && (
        <Button
        type={type}
          variant="contained"
          size="large"
          color="secondary"
          className={classes.buttonPink}
          classes={{
            root: classes.buttonRootLarge,
            label: classes.buttonLabel,
          }}
          disabled={disabled}
          onClick={onClick}
        >
          {label}
        </Button>
        )}
        {styleType === BwtButtons.largewhite && (
            <Button
                type={type}
                variant="contained"
                size="large"
                color="secondary"
                className={classes.buttonLargeWhite}
                classes={{
                    root: classes.buttonRootPink,
                    label: classes.buttonLabel,
                }}
                disabled={disabled}
                onClick={onClick}
            >
                {label}
            </Button>
          )}
    </React.Fragment>
  );
};
