import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Translate } from "react-localize-redux";
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: " 1.5",
      color: "var(--col-navy-500)",
    },
    infoLine: {
      letterSpacing: "0.4px",
      lineHeight: "1.5",
      paddingLeft:"5px"
    },
    detailsContainer: {
      padding: "10px 0px",
      color: "var(--col-navy-500)",
      display:"flex",
      alignItems:"center"
      
    },
    phoneNumber: {
      padding: "10px 0px",
      display:"flex"
    },
    emailIcon:{
      fontSize:"1em"
    }
  });
});
interface EmailAddressProps {
  email: string;
}
export const EmailAddress: React.FC<EmailAddressProps> = ({ email }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div>
            <span className={classes.title}>
              {translate("profile.emailAddress")}
            </span>
            <div className={classes.detailsContainer}>
              <MailOutlineIcon className={classes.emailIcon}></MailOutlineIcon>
              <span className={classes.infoLine}>{email}</span>
            </div>
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
