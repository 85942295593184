import { createMuiTheme } from "@material-ui/core/styles";

import "./index.css";

const colors = {
  navy: "#00174b", //var(--col-navy-500)
  pink: "#ff88bd", //var(--col-aidu-500)
  white: "#ffffff", //var(--col-grey-0)
  error: "#ed2b43", //var(--col-state-error-500)
};

const theme = createMuiTheme({
  palette: {
    primary: { main: colors.navy },
    secondary: {
      main: colors.pink,
      contrastText: colors.white,
    },
    error: { main: colors.error },

    common: {
      black: colors.navy ,
    },

    background: {
      default: colors.white,
    },

    text: {
      primary: colors.navy ,
      secondary: colors.white,
    },
  },

  typography: {
    fontFamily: "Soliden, sans-serif",
    body1: {
      fontSize: 14,
    },
  },
});

export default theme;
