import React, { useState, useEffect } from "react";
import { MiniLogo } from "../components/MiniLogo";
import { Translate } from "react-localize-redux";
import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { getError } from "../utils/accountAPI";
import { useUrlQuery } from "../utils/useUrlQuery";
import { AxiosResponse } from "axios";
import { ErrorDefinitions } from "../utils/errors";
import { BwtButton, BwtButtons } from "../components/BwtButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      "-ms-grid-row": "1",
      color: "var(--col-navy-500)",
      fontSize: "3.2em",
      lineHeight: "1.2em",
      fontWeight: "bold",
      [theme.breakpoints.only("xs")]: {
        fontSize: "2.2em",
      }
    },
    centeredGrid: {
      display: "grid",
      justifyContent: "center",
      textAlign: "center",
      "-ms-grid-columns": "2fr 0fr"
    },
    body: {
      paddingTop: theme.spacing(15),
    },
    paper: {
      "-ms-grid-row": "2",
      display: "flex",
      flexWrap: "wrap",
      paddingTop: theme.spacing(3),
      justifyContent: "center",
      "& > *": {
        width: theme.spacing(80),
        [theme.breakpoints.only("xs")]: {
          width: "100%"
        }
      },
      "& .MuiPaper-root": {
        borderStyle: "solid",
        backgroundColor: "var(--col-aidu-100)",
        borderColor: "var(--col-aidu-200)",
        color: "var(--col-state-error-500)",
        boxShadow: "none",
      },
    },
    paddingContainer: {
      "-ms-grid-row": "3",
      paddingTop: theme.spacing(3),
    },
    buttonContainer: {
      "-ms-grid-row": "4",
      paddingTop: theme.spacing(3),
    },
    requestIdText: {
      color: "var(--col-navy-500)",
      fontSize: "16px",
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "40px",
    },
  })
);

export interface IErrorState {
  errorType: string;
  requestId: string;
  loading: boolean;
}
const KnownErrors = ["unauthorized_client"];
export const ErrorPage: React.FC = () => {
  const [state, setState] = useState<IErrorState>({
    requestId: "",
    errorType: "",
    loading: true,
  });

  const classes = useStyles();
  const useParams = useUrlQuery();

  const errorId = useParams.get("errorId");
  const errorCode = useParams.get("code");

  useEffect(() => {
    if (errorId)
      getError(errorId).then(
        (res: any) => {
          handleErrorResponse(res);
        },
        (rej: any) => {
          setState({
            errorType: "generic_error",
            requestId: "GE-001",
            loading: false,
          });
        }
      );
    if (errorCode) {
      handleErrorCode(errorCode);
    }
  }, [errorId, errorCode]);

  const handleErrorResponse = (res: AxiosResponse<any>) => {
    var errorState: IErrorState = {
      requestId: res.data.requestId,
      errorType: "",
      loading: false,
    };
    const data = res.data;
    if (KnownErrors.includes(data.errorType)) {
      errorState.errorType = data.errorType;
    } else {
      errorState.errorType = "generic_error";
    }
    setState(errorState);
  };
  const handleErrorCode = (code: string) => {
    var errorState: IErrorState = {
      requestId: code,
      errorType:
        ErrorDefinitions.find((e) => e.errorCode === code)?.errorDescription ??
        "generic_error",
      loading: false,
    };
    setState(errorState);
  };

  return (
    <React.Fragment>
      <MiniLogo></MiniLogo>
      <Translate>
        {({ translate }) => (
          <div>
            {state.loading && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}
            {!state.loading && (
              <div className={classes.body}>
                <div className={classes.centeredGrid}>
                  <div className={classes.title}>
                    {translate("error.errorTitle")}
                  </div>
                  <div className={classes.paper}>
                    <Paper>
                      <p style={{ margin: "1em" }}>
                        {translate(`error.${state.errorType}`).toString()}
                      </p>
                    </Paper>
                  </div>
                  {state.requestId && (
                    <div className={classes.paddingContainer}>
                      <div className={classes.requestIdText}>
                        {translate("error.requestId") + " " + state.requestId}
                      </div>
                    </div>
                  )}
                  <div className={classes.buttonContainer}>
                    <BwtButton
                      label={translate("error.returnHome").toString()}
                      styleType={BwtButtons.white}
                      onClick={() => {
                        window.location.href = "/";
                      }}
                    ></BwtButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
