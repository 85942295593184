import React from "react";
import { MiniLogo } from "../components/MiniLogo";
import { Translate } from "react-localize-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { BwtButton, BwtButtons } from "../components/BwtButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: "var(--col-navy-500)",
      fontSize: "3.2em",
      lineHeight: "1.2em",
      fontWeight: "bold",
    },
    centeredGrid: {
      display: "grid",
      justifyItems: "center",
      textAlign: "center",
    },
    body: {
      paddingTop: theme.spacing(15),
    },
    button: {
      paddingTop: theme.spacing(15),
    },
  })
);

export const NotFoundPage: React.FC = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <MiniLogo></MiniLogo>
      <Translate>
        {({ translate }) => (
          <div className={classes.body}>
            <div className={classes.centeredGrid}>
              <div className={classes.title}>
                {translate("notFound.pageNotFound")}
              </div>
              <div className={classes.button}>
                <BwtButton
                  onClick={() => (window.location.href = "/")}
                  styleType={BwtButtons.pink}
                  label="To Home Page"
                ></BwtButton>
              </div>
            </div>
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
