import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  Translate,
  withLocalize,
  LocalizeContextProps,
} from "react-localize-redux";
import { useHistory } from "react-router-dom";
import AvatarIcon from "../resources/AvatarIcon";
import { MiniLogo } from "../components/MiniLogo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "48px",
      fontWeight: "bold",
      letterSpacing: "1.2px",
      textAlign: "center",
      color: "var(--col-navy-500)",
    },
    titleContainer: {
      display: "flex",
      paddingTop: "40px",
      paddingBottom: "30px",
      justifyContent: "center",
    },
    bodyContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "0 24px",
      color: "var(--col-navy-500)",
      "& p": {
        margin: 0,
      },
    },
    buttonRoot: {
      height: "34px",
    },
    topBar: {
      display: "flex",
      justifyContent: "space-between",
      padding: "15px 10px 0 10px",
    },
    section: {
      display: "grid",
      gridGap: "8px",
      marginBottom: "36px",
      width: "75%",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
  })
);

interface AppTermsAndConditionsPageProps extends LocalizeContextProps {}

const AppTermsAndConditionsPage: React.FC<AppTermsAndConditionsPageProps> = ({
  setActiveLanguage,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Translate>
      {({ translate }) => (
        <div>
          <div className={classes.topBar}>
            <Button
              classes={{ root: classes.buttonRoot }}
              onClick={() => {
                history.push("/");
              }}
            >
              <AvatarIcon color="var(--col-navy-500)" />
            </Button>
            <MiniLogo />
          </div>
          <div className={classes.titleContainer}>
            <span className={classes.title}>{translate("profile.appTermsAndConditions")}</span>
          </div>
          <div className={classes.bodyContainer}>
            <div className={classes.section}>
              <p>Terms and Conditions of the BWT Water Home App</p>
              <p>
                <b>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY</b>
              </p>
              <p>
                BY CLICKING ON THE "ACCEPT" BUTTON BELOW THE USER AGREES TO
                THESE TERMS AND CONDITIONS WHICH WILL BIND THE USER.
              </p>
            </div>
            <div className={classes.section}>
              <p>
                <b>1. General Information</b>
              </p>
              <p>
                <b>1.1</b> Welcome to the BWT Best Water Application
              </p>
              <p>
                This app (“App”) is being provided to the User (“the User“) by
                BWT Holding GmbH with its registered office at Walter Simmer
                Straße 4, in 5310 Mondsee, Austria, FN 96162 s, UID: ATU23473603
                (hereinafter referred to as “BWT” or “We”).
              </p>
              <p>
                The following Terms and Conditions (hereinafter “T&C”) set out
                the rules which apply to the User of the App.
              </p>
              <p>
                <b>1.2</b> Description
              </p>
              <p>
                The software is operated by BWT as a web-based SaaS solution.
                This enables the User to use the software stored and running on
                the servers of BWT or a service provider commissioned by BWT via
                an Internet connection and browser access for your own purposes
                during the term of this agreement and to store and process your
                data with the help of the software. The BWT Best Water Home App
                provides Users with insights into their various BWT products,
                including: Status display of your system Reminders about the
                system of your product Direct communication with your BWT
                professional Ability to order salt online Display of your usage
                Option to set holiday mode for your system Users can also
                receive notifications about their registered products, such as
                water filters, limescale protection systems, water dispensers,
                pool tester kits, and table water filters.
              </p>
              <p>
                <b>1.3</b> User Account
              </p>
              <p>
                In order to use the App, the User is required to create a user
                account. The registration process for the user account requires
                an email address and a password as well as further personal data
                according to our privacy policy. The User can use the App after
                completion of the registration process.
              </p>
              <p>
                <b>1.4</b> Costs
              </p>
              <p>
                The registration as well as the use of the App, including its
                Content and Services, are free of charge.
              </p>
              <p>
                <b>1.5</b> Term
              </p>
              <p>
                This Agreement shall enter into force upon acceptance of the
                Terms and Conditions of the App and shall terminate upon
                uninstallation of the App by the User.
              </p>
              <p>
                <b>1.6</b> Changes to the T&Cs and Latest Version
              </p>
              <p>
                BWT reserves the right to review and revise the T&C from time to
                time, in particular to satisfy the requirements of providers of
                platforms for the distribution of applications and to reflect
                changes in law or best practice or to deal with additional
                features which we introduce. We will inform the User about any
                such changes by sending the updated version of the T&C (“New
                T&C”) to the User’s email address provided in the registration
                process. If the User does not uninstall the App from the User’s
                device(s) within a one-moth-period after receiving the New T&C
                and therefore terminate this agreement, the New T&C shall apply
                for the use of the App.
              </p>
              <p>
                <b>1.7</b> Language
              </p>
              <p>The contract language is English.</p>
              <p>
                <b>1.8</b> Contact
              </p>
              <p>
                The User may contact BWT under bestwaterhomeapp@bwt.com or +43
                6232 50110 in case the User has any questions, complaints or
                comments on the App, the services or content.
              </p>
            </div>
            <div className={classes.section}>
              <p>
                <b>2. Intellectual Property Rights</b>
              </p>
              <p>
                <b>2.1</b> The App contains digital content and services of BWT
                and Third Parties (“Content” and “Services”).
              </p>
              <p>
                <b>2.2</b> Content
              </p>
              <p>
                Content includes all information, material and media, in
                particular text, data, artwork, graphics, photos, print,
                footage, software, sounds, music, recordings, videos and other
                moving and non-moving images and material created or contributed
                by Us or a Third Party and whether provided in stream,
                progressive download, electronic data or any other form on or
                via the App.
              </p>
              <p>
                <b>2.3</b> Services
              </p>
              <p>
                Services include the access to the App, the data supplied with
                the software, tools as the online documentation and content made
                available to the User via the App and any updates or supplements
                to it.
              </p>
              <p>
                <b>2.4</b> Ownership
              </p>
              <p>
                The User acknowledges and agrees that the App (including Content
                and Services) are owned by BWT and are subject to copyright and
                other applicable intellectual property rights and shall remain
                at any time, the sole and exclusive property of BWT, its
                licensors and suppliers. Except as expressly stated under
                Section 3, the User is not granted any intellectual property
                (including usage) rights in or to the App by implication and all
                rights in and to the App not expressly granted under Section 3
                are hereby reserved and retained by BWT.
              </p>
            </div>
            <div className={classes.section}>
              <p>
                <b>3. Usage Rights</b>
              </p>
              <p>
                <b>3.1</b> License Grant
              </p>
              <p>
                BWT grants the User the non-exclusive, non-transferable right,
                limited in time to the duration of the App usage of the User, to
                use the Software for his own purposes, to store and process his
                data within the means of the Software, to load the user
                interface of the Software for display on the screen into the
                main memory of the end devices used for this purpose in
                accordance with the agreement and to make the resulting
                reproductions of the user interface.
              </p>
              <p>
                This license grant shall apply accordingly to any updates and/or
                upgrades of the App, the Content and Services provided by BWT.
              </p>
            </div>
            <div className={classes.section}>
              <p>
                <b>4. User Obligations and Rights</b>
              </p>
              <p>
                <b>4.1</b> Obligations
              </p>
              <p>The User must:</p>
              <p>
                i. not use the App or any Service in any unlawful manner, for
                any unlawful purpose, or in any manner inconsistent with these
                terms, or act fraudulently or maliciously, for example, by
                hacking into or inserting malicious code, such as viruses, or
                harmful data, into the App, any Service or any operating system;
              </p>
              <p>
                ii. not infringe our intellectual property rights or those of
                any third party in relation to the Users usage of the App or any
                Service, including by the submission of any material (to the
                extent that such use is not licensed by these terms);
              </p>
              <p>
                iii. not transmit any material that is defamatory, offensive or
                otherwise objectionable in relation to the Users usage of the
                App or any Service;
              </p>
              <p>
                iv. not use the App or any Service in a way that could damage,
                disable, overburden, impair or compromise our systems or
                security or interfere with other the Users; and
              </p>
              <p>
                v. not collect or harvest any information or data from any
                Service or our systems or attempt to decipher any transmissions
                to or from the servers running any Service.
              </p>
              <p>
                <b>4.2</b> The user is obliged to keep the password chosen
                during registration as well as the other access data secret and
                to ensure that persons authorized by the user to whom access
                data is made available do likewise. BWT will not ask the user
                for his password at any time outside of the software.
              </p>
            </div>
            <div className={classes.section}>
              <p>
                <b>5. Warranty for Defects and Updates</b>
              </p>
              <p>
                <b>5.1</b> Disclaimer, Warranty for Defects for Free Apps
              </p>
              <p>
                <b>5.1.1</b> Defects
              </p>
              <p>
                In the event of defects, the statutory warranty provisions for
                consumers shall apply.
              </p>
              <p>
                <b>5.1.2</b> Availability
              </p>
              <p>
                BWT provides you with the software in its current version for
                use at the router exit of the data center in which the server
                with the software is located ("transfer point"). The Software,
                the computing power required for the operation of the Software
                in the data center and the storage and data processing space
                required for this purpose are provided by BWT. However, BWT does
                not owe the establishment and maintenance of the data connection
                between the IT systems of you and the described handover point.
              </p>
              <p>
                BWT does not warrant the continuous availability of the App, any
                Content and Services. Downtimes due to maintenance, software
                updates and due to circumstances (such as technical problems of
                third parties, force majeure) which are not within the BWT’s
                sphere of influence and for which BWT is therefore not
                responsible and which are not accessible via the Internet
                through the services offered by BWT cannot be completely
                avoided. The User declares not to assert any claims for damages
                and/or warranty claims for failures.
              </p>
              <p>
                BWT reserves the right to remove the App, Content or Services
                (or part thereof) for whatever reason or to interrupt the
                operation of the App, Content or Services (or part thereof) as
                may be necessary to perform routine or non-routine maintenance,
                error correction or other changes.
              </p>
              <p>
                <b>5.2</b> Updates
              </p>
              <p>
                <b>5.2.1</b> BWT is entitled to further develop and modify the
                Software, provided that such further development or modification
                (i) is necessary for BWT's compliance with mandatory legal
                requirements, (ii) only means an adaptation to the respective
                proven or current state of the art or (iii) does not go beyond
                what is customary in the trade and reasonable for the User in
                the case of comparable software, i.e. does not disappoint the
                User's legitimate expectation to continue using essential
                functions of the Software that is available.
              </p>
              <p>
                <b>5.2.2</b> BWT shall provide support and maintenance services
                for the Software according to the applicable consumer protection
                law.
              </p>
              <p>
                <b>5.2.3</b> BWT shall keep the software up to date to provide
                the User with all new updates and security patches free of
                charge. BWT will inform the User of the availability of new
                updates and security patches via the email address provided in
                the registration process. The User is obliged to install the
                updates and security patches immediately after they are made
                available. If the User fails to install an update or security
                patch, proper functioning of the software cannot be assured and
                BWT is not liable for such failure.
              </p>
              <p>
                <b>5.2.4</b> “Update” means updates of the Software for repairs,
                enhancements or new features applied by BWT to Users devices,
                including updates to the Documentation as a result of such
                updates.
              </p>
            </div>
            <div className={classes.section}>
              <p>
                <b>6. Liability of BWT</b>
              </p>
              <p>
                <b>6.1</b> BWT is liable to the User in all cases of contractual
                and non-contractual liability in case of intent and gross
                negligence, in case of culpable injury to life, body or health,
                in case of assumption of a guarantee and in case of liability
                according to the Product Liability Act.
              </p>
              <p>
                <b>6.2</b> In other cases, BWT is only liable in case of simple
                negligent violation of an essential contractual obligation, i.e.
                such a contractual obligation, the fulfillment of which enables
                the proper execution of the contract in the first place and on
                the compliance of which the user could regularly rely, and this
                is limited to the compensation of the foreseeable and typical
                damage. In all other cases the liability of BWT is excluded.
              </p>
              <p>
                <b>6.3</b> If damages to the user result from the loss of data,
                BWT is not liable for this, as far as the damages would have
                been avoided by a regular and complete backup of all relevant
                data by the user. The user is responsible to carry out a regular
                and complete data backup by himself or by a third party.
              </p>
            </div>
            <div className={classes.section}>
              <p>
                <b>7. Breaches, Terminations</b>
              </p>
              <p>
                <b>7.1</b> The User has the right to terminate this agreement at
                any time without giving reasons by uninstalling the App from his
                device(s).
              </p>
              <p>
                <b>7.2</b> BWT may, in its sole discretion, without prior
                notice, terminate this agreement and therefore the Users Account
                as well as the Users access to this App, including the content
                or Services contained therein, if (i) the User violates clause
                4.1 of this T&C or (ii) if BWT has notified the User of a breach
                of any other provision of this T&C, has given the User at least
                14 days' notice to remedy the breach and the User fails to
                remedy the breach within such period.
              </p>
            </div>
            <div className={classes.section}>
              <p>
                <b>8. Right of Withdrawal</b>
              </p>
              <p>
                <b>8.1</b> You have the right to withdraw from this agreement
                within 14 days without giving any reason. The withdrawal period
                is 14 days from the day of the conclusion of the agreement.To
                exercise the right of withdrawal, you must inform
                bestwaterhomeapp@bwt.com of your decision to withdraw from this
                agreement by means of a clear declaration (e.g. letter or e-mail
                sent by post). You may use the attached model withdrawal form
                for this purpose, which is, however, not mandatory. To meet the
                withdrawal deadline, it is sufficient for you to send your
                communication concerning your exercise of the right of
                withdrawal before the withdrawal period has expired.
              </p>
              <p>
                <b>8.2</b> If you withdraw from this agreement, BWT shall
                reimburse to you all payments received from you, including the
                costs of delivery (with the exception of the supplementary costs
                resulting from your choice of a type of delivery other than the
                least expensive type of standard delivery offered by BWT),
                without undue delay and in any event not later than 14 days from
                the day on which BWT is informed about the your decision to
                withdraw from this agreement. BWT will carry out such
                reimbursement using the same means of payment as you used for
                the initial transaction, unless you have expressly agreed
                otherwise; in any event, you will not incur any fees as a result
                of such reimbursement.
              </p>
              <p>
                <b>8.3</b> The right of withdrawal pursuant to § 18 para 1 FAGG
                (Distance and Outward Transactions Act) no longer applies if BWT
                has provided all it’s services in accordance with this
                agreement.
              </p>
              <p>
                <b>8.4</b> Model withdrawal form
              </p>
              <p>
                I/we (*) hereby revoke the contract concluded by me/us (*) for
                the purchase of the following goods (*)/the provision of the
                following service:________________________
              </p>
              <p>Ordered on (*) /received on (*):____________</p>
              <p>Name of consumer:____________</p>
              <p>Address of consumer</p>
              <p>
                Signature of consumer (only if this form is notified on paper)
              </p>
              <p>Date</p>
              <p>(*)Delete when inapplicable</p>
            </div>
            <div className={classes.section}>
              <p>
                <b>9.0 Online dispute resolution for consumers</b>
              </p>
              <p>
                <b>9.1</b> For our customers who are consumers, the European
                Commission provides a platform for out-of-court online dispute
                resolution (ODR platform), which can be accessed at{" "}
                <a href="https://www.ec.europa.eu/consumers/odr">
                  https://www.ec.europa.eu/consumers/odr
                </a>
                .
              </p>
              <p>
                <b>9.2</b> You can find our e-mail address in clause 1.8
                (Contact). We are neither obligated nor willing to participate
                in the dispute resolution process.
              </p>
            </div>
            <div className={classes.section}>
              <p>
                <b>10. Data Privacy</b>
              </p>
              <p>
                We only use any personal data we collect through the User’s use
                of the App and the Services in the ways set out in our privacy
                policy{" "}
                <a href="https://www.bwt.com/en/data-privacy/">
                  https://www.bwt.com/en/data-privacy/
                </a>
              </p>
            </div>
            <div className={classes.section}>
              <p>
                <b>11. Final Provisions</b>
              </p>
              <p>
                <b>11.1</b> Additional Conditions of or Regarding Platform
                Provider
              </p>
              <p>
                Depending on the platform of a respective service provider from
                which the App was downloaded, additional terms and conditions
                may apply for the Users use of the App, the Content and the
                Services. It is the User’s sole responsibility to determine
                these supplementary or (as the case may be) prevailing terms and
                conditions and the User undertakes to comply with them
                accordingly.
              </p>
              <p>
                <b>11.2</b> Amendments and supplements to these terms and
                conditions must be made in writing. This shall also apply in
                particular to any waiver of this written form clause.
              </p>
              <p>
                <b>11.3</b> Should individual provisions of these terms and
                conditions be or become invalid, this shall not affect the
                validity of the remaining provisions. The parties undertake to
                replace the invalid provision with a provision that comes
                closest to the economic intent of the invalid provision in a
                legally permissible manner. The same shall apply in the event of
                gaps in the terms and conditions.
              </p>
              <p>
                <b>11.4</b> Unless otherwise agreed, you can send all
                declarations to BWT in any case by e-mail, using the contact
                form accessible from any of our websites. BWT on its part can
                send declarations to you by e-mail or letter to the addresses
                that you have specified as current contact information in your
                user account.
              </p>
              <p>
                <b>11.5</b> The laws of the Republic of Austria shall apply to
                the exclusion of the UN Convention on Contracts for the
                International Sale of Goods. For consumers who have their
                habitual residence in another country at the time of the
                conclusion of the contract, the application of mandatory legal
                provisions of this country remains unaffected by the choice of
                law made in sentence 1.
              </p>
            </div>
          </div>
        </div>
      )}
    </Translate>
  );
};

export default withLocalize(AppTermsAndConditionsPage);
