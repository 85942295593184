import * as React from "react";
import { SVGProps } from "react";
const ProAppIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="39.4919"
      height="39.4919"
      rx="7.02079"
      fill="url(#paint0_linear_13777_44406)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.6453 14.9377L12.4454 6.96289V9.49338L8.46876 7.38262V23.371C8.46669 24.8619 8.82057 26.3318 9.50096 27.6584C10.1814 28.985 11.1686 30.13 12.3806 30.9983C13.5927 31.8665 14.9944 32.4329 16.4694 32.6503C17.9444 32.8677 19.45 32.7298 20.861 32.2482C21.26 32.3056 21.6629 32.3324 22.0661 32.3284C24.1194 32.3275 26.1146 31.6471 27.7407 30.3933C29.3667 29.1394 30.532 27.3827 31.0548 25.397C31.5777 23.4114 31.4286 21.3086 30.631 19.4166C29.8333 17.5245 28.4319 15.9497 26.6453 14.9377ZM17.8487 31.3071C16.8038 31.3081 15.7689 31.1031 14.8034 30.7036C13.8378 30.3042 12.9605 29.7182 12.2217 28.9793C11.4829 28.2403 10.8971 27.3629 10.4978 26.3973C10.0986 25.4317 9.89367 24.3968 9.8949 23.3518V9.69438L14.3328 12.1661V23.3518C14.3328 24.0046 14.5145 24.6445 14.8577 25.1998C15.2008 25.7551 15.6918 26.2039 16.2757 26.4958C16.8595 26.7878 17.5131 26.9114 18.1633 26.8528C18.8134 26.7942 19.4344 26.5558 19.9566 26.1641C20.4788 25.7725 20.8817 25.2432 21.1201 24.6355C21.3584 24.0278 21.4229 23.3657 21.3061 22.7235C21.1894 22.0812 20.8962 21.4842 20.4592 20.9992C20.0223 20.5142 19.4589 20.1605 18.8323 19.9777V15.4787C20.8436 15.7226 22.6857 16.7259 23.9816 18.2833C25.2776 19.8407 25.9294 21.8345 25.8038 23.8566C25.6781 25.8788 24.7845 27.7765 23.3057 29.1615C21.8269 30.5465 19.8748 31.314 17.8487 31.3071Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_13777_44406"
        x1="39.4919"
        y1="39.4919"
        x2="0"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E5066B" />
        <stop offset="1" stop-color="#F7B6CA" />
      </linearGradient>
    </defs>
  </svg>
);
export default ProAppIcon;
