import React from "react";
import { useAuthContext } from "../Providers/authProvider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import { useUrlQuery } from "../utils/useUrlQuery";

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "40px",
    },
  })
);
export const AuthRedirect: React.FC = ({ children }) => {
  const { authState } = useAuthContext();
  const classes = useStyles();
  const urlParams = useUrlQuery();
  const history = useHistory();
  var state = "redirect" || "children" || "loading" || "details";

  const getState = () => {
    const isExternal = urlParams.get("external") === "true";

    if (authState.isAuth) {
      if (authState?.issue === "Profile missing" && history.location.pathname !== "/register/details") {
        return "details";

      } else if (isExternal) {
        return "children";

      } else if (!history.location.pathname.startsWith("/profile")) {
        return "redirect";
      }

    } else if (authState.state === "loading") {
      return "loading";
    }

    return "children";
  };

  state = getState();

  return (
    <React.Fragment>
      {authState.isAuth === false && authState?.state === "loading" && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {state === "redirect" && <Redirect to="profile" />}
      {state === "children" && <div> {children} </div>}
      {state === "details" && <Redirect to="/register/details?external=true" />}
    </React.Fragment>
  );
};
