import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import bwtLogo from "../resources/BWT.svg";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    logoContainer: {
      display: "block",
      position: "absolute",
      top: "15px",
      right: "20px",
    },
    logo: {
      width: "100px",
      height: "auto"
    },
  });
});
export const MiniLogo: React.FC = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={bwtLogo} alt=""></img>
      </div>
    </React.Fragment>
  );
};
