import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import {
  Translate,
  withLocalize,
  LocalizeContextProps,
} from "react-localize-redux";
import {
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { SimpleInput } from "../components/SimpleInput";
import AppleIcon from "@material-ui/icons/Apple";
import GoogleSvg from "../components/GoogleSvg";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useUrlQuery } from "../utils/useUrlQuery";
import { Logo } from "../components/Logo";
import { AuthRedirect } from "../components/AuthRedirect";
import { externalLogin, login } from "../utils/accountAPI";
import { BwtButton, BwtButtons } from "../components/BwtButton";
import { useRegisterContext } from "../Providers/registerProvider";
import { useAuthContext } from "../Providers/authProvider";
import { ErrorCard } from "../components/ErrorCard";
import { MaskedInput } from "../components/MaskedInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: "0px",
      padding: `8px 80px 20px 80px`,
      marginTop: theme.spacing(2),
      boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.16)",
      border: "solid 1px #e1e3e4",
      [theme.breakpoints.only("xs")]: {
        border: "none",
        boxShadow: "none",
        marginTop: theme.spacing(1),
        width: "100%",
        padding: "0vh 5vw",
      },
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      margin: `${theme.spacing(0)} auto`,
      justifyContent: "center",
    },
    loginBtn: {
      marginTop: theme.spacing(1),
      flexGrow: 1,
      borderRadius: "50px",
      height: "50px",
    },
    capitalizeButton: {
      textTransform: "capitalize",
      boxShadow: "none",
      width: theme.spacing(40),
      height: "50px",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    cardHeader: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "1.67",
      textAlign: "left",
      color: "var(--col-navy-500)",
    },
    headerRoot: {
      paddingBottom: "14px",
      [theme.breakpoints.only("xs")]: {
        padding: "0px 16px",
      },
    },
    greyContainer: {
      backgroundColor: "var(--col-grey-100)",
      display: "grid",
      margin: `0px -${theme.spacing(10)}px -30px`,
      justifyContent: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.only("xs")]: {
        display: "flow-root",
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        margin: "0vh -5vw -30px",
        paddingTop: theme.spacing(1),
      },
      msGridColumns: "2fr 0fr",
    },
    appleButton: {
      backgroundColor: "#000000",
      marginBottom: "23px",
      "&:hover": {
        backgroundColor: "rgb(35, 35, 35)",
      },
      "-ms-grid-row": "1",
      "-ms-grid-column-align": "center",
    },
    googleButton: {
      "-ms-grid-row": "2",
      "-ms-grid-column-align": "center",
      backgroundColor: "var(--col-grey-0)",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.24)",
    },
    bottomCard: {
        display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      boxShadow: "none",
      paddingTop: theme.spacing(1),
      [theme.breakpoints.only("xs")]: {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    checkBoxRoot: {
      color: "var(--col-navy-500)",
    },
    textLink: {
      fontSize: "14px",
      lineHeight: "2.14",
      letterSpacing: "0.35px",
      color: "var(--col-navy-500)",
      textDecoration: "underline",
      cursor: "pointer",
      marginTop: theme.spacing(1),
    },
    divider: {
      backgroundColor: "var(--col-navy-500)",
      margin: "0 -100px",
      [theme.breakpoints.only("xs")]: {
        margin: "0px",
      },
    },
    textInDivider: {
      position: "relative",
      transform: "translate(0px, -12px)",
      justifyContent: "center",
      background: "white",
      display: "flex",
      width: "fit-Content",
      marginLeft: "33%",
      padding: "0px 10px",
      marginBottom: "30px",
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "40px",
      },
      extraButton: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
      },
    bottomCardContent: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.only("xs")]: {
        width: "100%",
        padding: "1vh 5vw 0vh 5vw",
      },
    },
    cardContent: {
      padding: "8px 16px",
      [theme.breakpoints.only("xs")]: {
        padding: "0px 16px",
      },
    },
  })
);

interface LandingProps extends LocalizeContextProps {}

interface ILoginState {
  error: boolean;
  rememberMe: boolean;
  disabledButton: boolean;
}
const initLoginState: ILoginState = {
  error: false,
  rememberMe: false,
  disabledButton: false,
};

const LandingPage: React.FC<LandingProps> = ({ setActiveLanguage }) => {
  const classes = useStyles();
  const history = useHistory();
  const [landingState, setLandingState] = useState({ loading: false });
  const [loginState, setLoginLocalState] = useState<ILoginState>(
    initLoginState
  );
  const { setAuthState } = useAuthContext();
  const { handleSubmit, control, errors, watch } = useForm({});
  const { resetState } = useRegisterContext();

  const returnUrl = useUrlQuery().get("ReturnUrl") ||  localStorage.getItem('returnUrl')|| "/profile";
  const setLoading = (loading: boolean) => {
    setLandingState({ loading: loading });
  };

  useEffect(() => {
    resetState();
    localStorage.setItem("returnUrl", returnUrl);
  }, [resetState, returnUrl]);

  const setRememberMe = (remember: boolean) => {
    setLoginLocalState({ ...loginState, rememberMe: remember });
  };
  const setError = (error: boolean) => {
    setLoginLocalState({ ...loginState, error: error });
  };
  const setDisableButton = (disable: boolean) => {
    setLoginLocalState({ ...loginState, disabledButton: disable });
  };

  const handleLogin = (data: Record<string, any>) => {
    setDisableButton(true);
    login(
      data["email"],
      data["password"],
      loginState.rememberMe,
      localStorage.getItem("returnUrl") ?? "/profile"
    )
      .then(
        (val: any) => {
          localStorage.removeItem("returnUrl")
          if (val.redirectUrl) {
            if (val.redirectUrl.startsWith("/connect/authorize")) {
              window.location.href = val.redirectUrl;
              return;
            }
            if (val?.profile) {
              setAuthState({ isAuth: true, profile: val.profile });
            } else if (val?.issue === "Profile missing") {
              setAuthState({ isAuth: true, issue: "Profile missing" });
            }
            history.push(val.redirectUrl);
            return;
          }

          if (val?.errorCode === "1202") {
            history.push("/error?code=L-001");
          }
        },
        () => {
          setDisableButton(false);
          setError(true);
        }
      )
      .catch(() => history.push("/error?code=L-002"));
  };

  const handleRegister = () => {
    history.push("/register");
  };
  return (
    <React.Fragment>
      <Logo></Logo>
      {landingState.loading === true && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {landingState.loading === false && (
        <AuthRedirect>
          <Translate>
            {({ translate }) => (
              <div>
                {loginState.error && (
                  <ErrorCard
                    text={"login.accountNotFound"}
                    header={"landing.weCouldNotLogYouIn"}
                  ></ErrorCard>
                )}
                <form
                  className={classes.container}
                  autoComplete="on"
                  onSubmit={handleSubmit((data) => handleLogin(data))}
                >
                  <Card className={classes.card}>
                    <CardHeader
                      classes={{
                        title: classes.cardHeader,
                        root: classes.headerRoot,
                      }}
                      title={translate("landing.sign-in")}
                    />
                    <CardContent style={{ padding: "8px 16px" }}>
                                          <div>
                        <SimpleInput
                          errors={errors}
                          id={"email"}
                          type="email"
                          label={translate("login.email") as string}
                          control={control}
                          required={true}
                          autocomplete="email"
                        ></SimpleInput>
                        <div style={{ marginBottom: "-10px" }}>
                          <MaskedInput
                            value={watch("password", "")}
                            id="password"
                            label={translate("login.password") as string}
                            placeHolder={translate("login.password") as string}
                            required={true}
                            errors={errors}
                            control={control}
                          ></MaskedInput>
                        </div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              classes={{ root: classes.checkBoxRoot }}
                              color="secondary"
                              checked={loginState.rememberMe}
                              onChange={(e) => {
                                setRememberMe(e.target.checked);
                              }}
                              name="rememberMe"
                            />
                          }
                          label={translate("login.rememberMe")}
                        ></FormControlLabel>
                      </div>
                    </CardContent>
                    <CardActions
                      style={{ padding: "0px 16px", marginBottom: "8px" }}
                    >
                      <BwtButton
                        label={translate("login.next").toString()}
                        styleType={BwtButtons.pink}
                        type="submit"
                      ></BwtButton>
                    </CardActions>
                    <CardContent
                      style={{ padding: "0px 16px", marginBottom: "8px" }}
                    >
                      <a
                        className={classes.textLink}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push("/forgotPassword");
                        }}
                        href="/"
                      >
                        {translate("login.forgotPassword")}
                      </a>
                    </CardContent>
                    <div className={classes.greyContainer}>
                      <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        className={classes.appleButton}
                        onClick={() => {
                          setLoading(true);
                          externalLogin(
                            "Apple",
                            localStorage.getItem("returnUrl")
                          );
                        }}
                        classes={{ root: classes.capitalizeButton }}
                      >
                        <AppleIcon></AppleIcon>
                        {translate("landing.sign-inWithApple")}
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.googleButton}
                        onClick={() => {
                          setLoading(true);
                          externalLogin(
                            "Google",
                            localStorage.getItem("returnUrl")
                          );
                        }}
                        classes={{ root: classes.capitalizeButton }}
                      >
                        <GoogleSvg></GoogleSvg>
                        {translate("landing.sign-inWithGoogle")}
                      </Button>
                    </div>
                  </Card>
                </form>
                <Card className={classes.bottomCard}>
                  <CardContent
                    className={classes.bottomCardContent}
                    style={{ paddingBottom: "0px" }}
                  >
                    <BwtButton
                      styleType={BwtButtons.largewhite}
                      label={translate("landing.create").toString()}
                      onClick={() => handleRegister()}
                    ></BwtButton>
                    <div className={classes.extraButton} >
                        <a
                            className={classes.textLink}
                            onClick={(e) => {
                                e.preventDefault();
                                history.push("/DeleteBWTUserAccount");
                            }}
                            href="/"
                        >
                          {translate("profile.deleteAccountFindOut")}
                        </a>
                    </div>
                    <div className={classes.extraButton} >
                        <a
                            className={classes.textLink}
                            onClick={(e) => {
                                e.preventDefault();
                                history.push("/AppTermsAndConditions");
                            }}
                            href="/"
                        >
                          {translate("profile.appTermsAndConditions")}
                        </a>
                    </div>
                  </CardContent>
                </Card>

              </div>
            )}
          </Translate>
        </AuthRedirect>
      )}
    </React.Fragment>
  );
};

export default withLocalize(LandingPage);
