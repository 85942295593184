import React, { ReactElement, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";

import {
  LocalizeProvider,
  withLocalize,
  LocalizeContextProps,
  Language,
} from "react-localize-redux";

import langCS from "../resources/i18n/cs.translations.json";
import langDA from "../resources/i18n/da.translations.json";
import langDE from "../resources/i18n/de.translations.json";
import langEN from "../resources/i18n/en.translations.json";
import langES from "../resources/i18n/es.translations.json";
import langFI from "../resources/i18n/fi.translations.json";
import langFR from "../resources/i18n/fr.translations.json";
import langHU from "../resources/i18n/hu.translations.json";
import langIT from "../resources/i18n/it.translations.json";
import langNL from "../resources/i18n/nl.translations.json";
import langNO from "../resources/i18n/no.translations.json";
import langPL from "../resources/i18n/pl.translations.json";
import langSV from "../resources/i18n/sv.translations.json";
import langZH from "../resources/i18n/zh_Hans.translations.json";
import langRU from "../resources/i18n/ru.translations.json";
import langRO from "../resources/i18n/ro.translations.json";
import langHR from "../resources/i18n/hr.translations.json";
import langSI from "../resources/i18n/si.translations.json";
import langUK from "../resources/i18n/uk.translations.json";
import langPT from "../resources/i18n/pt.translations.json";

import langCountryCS from "../resources/i18n/cs.country_translations.json";
import langCountryDA from "../resources/i18n/da.country_translations.json";
import langCountryDE from "../resources/i18n/de.country_translations.json";
import langCountryEN from "../resources/i18n/en.country_translations.json";
import langCountryES from "../resources/i18n/es.country_translations.json";
import langCountryFI from "../resources/i18n/fi.country_translations.json";
import langCountryFR from "../resources/i18n/fr.country_translations.json";
import langCountryHU from "../resources/i18n/hu.country_translations.json";
import langCountryIT from "../resources/i18n/it.country_translations.json";
import langCountryNL from "../resources/i18n/nl.country_translations.json";
import langCountryNO from "../resources/i18n/no.country_translations.json";
import langCountryPL from "../resources/i18n/pl.country_translations.json";
import langCountrySV from "../resources/i18n/sv.country_translations.json";

export const getFirstAvailableLang = (
  available: Language[] = [],
  languages: (string | null | undefined)[] = [],
  fallback = "en"
): string => {
  var availableObj = available.reduce((map: { [key: string]: string }, obj) => {
    map[obj.code.toLowerCase()] = obj.name || "";
    return map;
  }, {});

  for (let idx = 0; idx < languages.length; idx++) {
    const currLang = languages[idx];
    if (currLang) {
      const lang = currLang.substring(0, 2).toLowerCase();
      if (availableObj.hasOwnProperty(lang)) {
        return lang;
      }
    }
  }

  return fallback;
};

export const languages = [
  { name: "Czech", code: "cs" },
  { name: "Danish", code: "da" },
  { name: "German", code: "de" },
  { name: "English", code: "en" },
  { name: "Spanish", code: "es" },
  { name: "Finnish", code: "fi" },
  { name: "French", code: "fr" },
  { name: "Hungarian", code: "hu" },
  { name: "Italian", code: "it" },
  { name: "Dutch", code: "nl" },
  { name: "Norwegian", code: "no" },
  { name: "Polish", code: "pl" },
  { name: "Swedish", code: "sv" },
  { name: "Chinese", code: "zh" },
  { name: "Russian", code: "ru" },
  { name: "Romanian", code: "ro" },
  { name: "Croatian", code: "hr" },
  { name: "Slovenian", code: "si" },
  { name: "Ukrainian", code: "uk" },
  { name: "Portuguese", code: "pt" },
];
interface I18nConfigProps extends LocalizeContextProps {
  children: ReactElement;
}

const I18nConfig = withLocalize(
  ({ children, initialize, addTranslationForLanguage }: I18nConfigProps) => {
    useState(() => {
      initialize({
        languages: languages,
        options: {
          renderToStaticMarkup,
          defaultLanguage: "en",
          onMissingTranslation: ({ defaultTranslation }) => defaultTranslation,
        },
      });

      addTranslationForLanguage(langFR, "fr");
      addTranslationForLanguage(langCS, "cs");
      addTranslationForLanguage(langDA, "da");
      addTranslationForLanguage(langDE, "de");
      addTranslationForLanguage(langEN, "en");
      addTranslationForLanguage(langES, "es");
      addTranslationForLanguage(langFI, "fi");
      addTranslationForLanguage(langFR, "fr");
      addTranslationForLanguage(langHU, "hu");
      addTranslationForLanguage(langIT, "it");
      addTranslationForLanguage(langNL, "nl");
      addTranslationForLanguage(langNO, "no");
      addTranslationForLanguage(langPL, "pl");
      addTranslationForLanguage(langSV, "sv");
      addTranslationForLanguage(langZH, "zh");
      addTranslationForLanguage(langRU, "ru");
      addTranslationForLanguage(langRO, "ro");
      addTranslationForLanguage(langUK, "uk");
      addTranslationForLanguage(langHR, "hr");
      addTranslationForLanguage(langSI, "si");
      addTranslationForLanguage(langPT, "pt");

      addTranslationForLanguage(langCountryCS, "cs");
      addTranslationForLanguage(langCountryDA, "da");
      addTranslationForLanguage(langCountryDE, "de");
      addTranslationForLanguage(langCountryEN, "en");
      addTranslationForLanguage(langCountryES, "es");
      addTranslationForLanguage(langCountryFI, "fi");
      addTranslationForLanguage(langCountryFR, "fr");
      addTranslationForLanguage(langCountryHU, "hu");
      addTranslationForLanguage(langCountryIT, "it");
      addTranslationForLanguage(langCountryNL, "nl");
      addTranslationForLanguage(langCountryNO, "no");
      addTranslationForLanguage(langCountryPL, "pl");
      addTranslationForLanguage(langCountrySV, "sv");

      return true;
    });

    return children;
  }
);

interface LocalizationProps {
  children: ReactElement;
  store: any;
}

const Localization: React.FC<LocalizationProps> = ({ children, store }) => (
  <LocalizeProvider store={store}>
    <I18nConfig>{children}</I18nConfig>
  </LocalizeProvider>
);

export default Localization;
