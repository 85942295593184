import React, { useState } from "react";
import { makeStyles, createStyles, Theme, FormControl, TextField } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { FieldError, NestDataObject, Controller, Control } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import { getNativeName } from "../utils/supportedLanguages";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorLabel: {
      color: "var(--col-accent-magnesium-500)",
      fontSize: "12px",
      display: "flex",
    },
    formControl: {
      marginBottom: "15px",
      height: "50px",
      display: "flex",
      width: theme.spacing(40),
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    underline: {
      backgroundColor: "white",
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    root: {
      height: "calc(100% - 3px)",
      paddingTop: "1px",
      "& .MuiFilledInput-root": {
        marginLeft: "1px",
        backgroundColor: "var(--col-grey-0)",
        paddingTop: "13px",
        width: "calc(100% - 1px)",
        height: "calc(100% - 3px)",
      },
      "& .MuiFilledInput-underline": {
        "&::before": { borderBottom: "none" },
        "&::after": { borderBottom: "none" },
      },
      "& .MuiSvgIcon-root": {
        color: "var(--col-navy-500)"
      }
    },
    label: {
      fontSize: "16px",
      lineHeight: "1.5",
      letterSpacing: "0.4px",
      color: "var(--col-navy-500)",
    },
    input: {
      width: theme.spacing(40),
      border: "solid 1px var(--col-navy-500)",
      marginBottom: "20px",
      height: "48px",
      "& .MuiFormLabel-root.Mui-focused": {
        color: "var(--col-navy-500)",
      },
      "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
        transform: "translate(12px, 1.2px) scale(0.75)",
      },
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    errorInput: {
      boxShadow: "0 0 5px 0 var(--col-accent-magnesium-500)",
      border: "solid 1px var(--col-accent-magnesium-500)",
    },
    inputFocus: {
      border: "solid 1px",
      borderColor: "var(--col-aidu-500)",
      boxShadow: "0 0 5px 0 var(--col-aidu-500)",
    },
  })
);

interface BwtLanguageSelectionProps {
  selections: string[];
  errors: NestDataObject<Record<string, any>, FieldError>;
  control: Control<Record<string, any>>;
  controllerName: string;
  label: string;
}

export const BwtLanguageSelection: React.FC<BwtLanguageSelectionProps> = ({
  selections,
  errors,
  control,
  controllerName,
  label,
}) => {

  const [state, setState] = useState({
    focused: false,
  });

  const classes = useStyles();

  return (
    <div
      className={`${classes.input} ${
        errors?.[controllerName] ? classes.errorInput : ""
        } ${state.focused ? classes.inputFocus : ""}  `}
    >
      <FormControl className={classes.formControl}>
        <Controller
          as={
            <Autocomplete
              options={selections}
              getOptionLabel={(option) => getNativeName(option)}
              renderOption={(option) => <span>{getNativeName(option)}</span>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  variant="filled"
                  className={classes.root}
                  inputProps={{
                    ...params.inputProps,
                    onFocusCapture: () => setState({ focused: true }),

                    onBlurCapture: () => setState({ focused: false }),

                    classes: {
                      underline: classes.underline,
                      root: classes.root,
                    },
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                  InputLabelProps={{ classes: { filled: classes.label } }}
                />
              )}
            />
          }
          onChange={([event, data]) => {
            return data
          }}
          name={controllerName}
          rules={{ required: true }}
          control={control}
        />
        {errors?.[controllerName] && (
          <span className={classes.errorLabel}><Translate id="validation.required" /></span>
        )}
      </FormControl>
    </div>
  );
};
