import React from "react";

interface LoginState {
  email: string;
  returnUrl:string;
}
interface LoginContextState {
  state: LoginState;
  setLoginState: (value: Partial<LoginState>) => void;
}
const LoginStateInitValues: LoginState ={
  email:"",
  returnUrl:""
}
export const LoginContext = React.createContext<LoginContextState>({
  state:LoginStateInitValues,
  setLoginState: () => {}
});

const LoginProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<LoginState>(LoginStateInitValues);

  const setLoginState = (value: Partial<LoginState>) => {
    setState({ ...state, ...value });
  };

  return (
    <LoginContext.Provider value={{ setLoginState, state }}>
      {children}
    </LoginContext.Provider>
  );
};

export function useLoginContext(): LoginContextState {
  const { state, setLoginState } = React.useContext(LoginContext);
  return { state, setLoginState };
}

export default LoginProvider;
