import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import { SupportedCountyCodes } from "../utils/supportedCountries";
import { sharedStyles } from "../themes/sharedStyles";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    ...sharedStyles(theme),
    contactDetails: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: " 1.5",
      color: "var(--col-navy-500)",
    },
    detailsContainer: {
      padding: "10px 0px",
      display: "grid",
      color: "var(--col-navy-500)",
      "-ms-grid-rows": "2fr 2fr",
    },
    phoneNumber: {
      padding: "10px 0px",
      display: "flex",
    },
    infoLine: {
      letterSpacing: "0.4px",
      lineHeight: "1.5",
      maxWidth: "300px"
    },
  });
});

interface ContactDetailsProps extends LocalizeContextProps {
  firstName: string;
  lastName: string;
  postcode: string;
  address: string;
  city: string;
  country: string;
  phoneNumber?: string;
}

const ContactDetailsLocal: React.FC<ContactDetailsProps> = ({ firstName, lastName, postcode, address, city, country, phoneNumber, translate }) => {
  const classes = useStyles();
  const countryCodeToName = () => SupportedCountyCodes.find((c) => c === country) ?? "countryNotFound";

  return (
    <div>
      <span className={classes.contactDetails}>
        {translate("profile.contactDetails")}
      </span>
      <div className={classes.detailsContainer}>
        <div className={`${classes.infoLine} ${classes.gridRow1}`}>
          {firstName + " " + lastName}
        </div>
        <div className={`${classes.infoLine} ${classes.gridRow2}`}>
          {address}
        </div>
        <div className={`${classes.infoLine} ${classes.gridRow3}`}>
          {postcode + " " + city}
        </div>
        <div className={`${classes.infoLine} ${classes.gridRow4}`}>
          {translate("countries." + countryCodeToName().toLowerCase())}
        </div>
        <div className={`${classes.phoneNumber} ${classes.gridRow5}`}>
          <PhoneAndroidIcon></PhoneAndroidIcon>
          <span>
            {(phoneNumber === undefined || phoneNumber === "" || phoneNumber === null)
              ? translate("profile.notProvided")
              : phoneNumber}
          </span>
        </div>
      </div>
    </div>
  );
};

export const ContactDetails = withLocalize(ContactDetailsLocal);
