import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Translate } from "react-localize-redux";
import { BwtButton } from "../components/BwtButton";
import { ContactDetails } from "../components/ContactDetails";
import { EmailAddress } from "../components/EmailAddress";
import { Newsletter } from "../components/Newsletter";
import { DeleteAccount } from "../components/DeleteAccount";
import { Password } from "../components/Password";
import { Paper } from "@material-ui/core";
import { ProfileDto } from "../utils/accountAPI";
import { useHistory } from "react-router-dom";
import { sharedStyles } from "../themes/sharedStyles";
import { AppTermsAndConditions } from "./AppTermsAndConditions";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    ...sharedStyles(theme),
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: " 1.5",
      color: "var(--col-navy-500)",
    },
    detailsContainer: {
      padding: "10px 0px",
      color: "var(--col-navy-500)",
      display: "flex",
    },
    body: {
      display: "flex",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      justifyContent: "center",
      [theme.breakpoints.only("xs")]: {
        display: "grid",
        margin: "0 24px",
      },
    },
    container: {
      width: theme.spacing(40),
      [theme.breakpoints.only("xs")]: {
        width: "100%",
        paddingBottom: theme.spacing(3),
      },
    },
    paper: {
      display: "flex",
      flexWrap: "wrap",
      paddingTop: theme.spacing(3),
      justifyContent: "center",
      "& > *": {
        width: theme.spacing(80),
      },
      "& .MuiPaper-root": {
        backgroundColor: "var(--col-aidu-500)",
        color: "var(--col-grey-0)",
      },
    },
    flexRight: {
      display: "grid",
      justifyContent: "flex-end",
      [theme.breakpoints.only("xs")]: {
        justifyContent: "unset",
      },
      "-ms-grid-rows": "2fr 2fr",
    },
    info: {
      width: theme.spacing(27),
      color: "var(--col-navy-500)",
      fontSize: "14px",
    },
  });
});
interface IProfileBodyProps {
  profile: ProfileDto;
}
export const ProfileBody: React.FC<IProfileBodyProps> = ({ profile }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div>
            {!profile.EmailConfirmed && (
              <div className={classes.paper}>
                <Paper>
                  <p style={{ margin: "1em" }}>
                    {translate(
                      "profile.emailNotConfirmed",
                      {},
                      { renderInnerHtml: true }
                    )}
                  </p>
                </Paper>
              </div>
            )}
            <div className={classes.body}>
              <div className={classes.container}>
                <ContactDetails
                  firstName={profile.FirstName}
                  lastName={profile.LastName}
                  address={profile.Street}
                  postcode={profile.PostalCode}
                  city={profile.City}
                  country={profile.CountryCode}
                  phoneNumber={profile.PhoneNumber}
                ></ContactDetails>
                <BwtButton
                  label={translate("profile.changeContactDetails").toString()}
                  onClick={() => history.push("/profile/detailsEdit")}
                ></BwtButton>
              </div>
              <div className={classes.container}>
                <div className={classes.flexRight}>
                  <div className={classes.gridRow1}>
                    <EmailAddress email={profile.Email}></EmailAddress>
                  </div>
                  <div className={classes.gridRow2}>
                    <BwtButton
                      label={translate("profile.changeEmailAddress").toString()}
                      onClick={() => history.push("/profile/emailEdit")}
                    ></BwtButton>
                  </div>
                  <div className={classes.gridRow3}>
                    <Password></Password>
                  </div>
                  <div className={classes.gridRow4}>
                    <BwtButton
                      label={
                        profile.isPasswordSet
                          ? translate("profile.changePassword").toString()
                          : translate("profile.setPassword").toString()
                      }
                      onClick={() => history.push("/profile/passEdit")}
                    ></BwtButton>
                  </div>
                  <div className={classes.gridRow5}>
                    <Newsletter optedIn={profile.canContact}></Newsletter>
                  </div>
                  <div className={classes.gridRow6}>
                    <BwtButton
                      label={translate("profile.updatePreferences").toString()}
                      onClick={() =>
                        history.push("/profile/emailPreferencesEdit")
                      }
                    ></BwtButton>
                  </div>
                  <div className={classes.gridRow7}>
                    <DeleteAccount></DeleteAccount>
                  </div>
                  <div className={classes.gridRow8}>
                    <BwtButton
                      label={translate("profile.deleteAccountInformation").toString()}
                      onClick={() => history.push("/DeleteBWTUserAccount")}
                    ></BwtButton>
                  </div>
                  <div className={classes.gridRow9}>
                    <AppTermsAndConditions />
                  </div>
                  <div className={classes.gridRow10}>
                    <BwtButton
                      label={translate("profile.appTermsAndConditions").toString()}
                      onClick={() => history.push("/AppTermsAndConditions")}
                    ></BwtButton>
                  </div>
                  {!profile.isPasswordSet && (
                    <div className={classes.info}>
                      <p>{translate("profile.settingPasswordInfo")}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
