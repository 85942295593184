import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  Translate,
  withLocalize,
  LocalizeContextProps,
} from "react-localize-redux";
import { useHistory } from "react-router-dom";
import HomeAppIcon from "../components/HomeAppIcon";
import ProAppIcon from "../components/ProAppIcon";
import AvatarIcon from "../resources/AvatarIcon";
import { MiniLogo } from "../components/MiniLogo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "48px",
      fontWeight: "bold",
      letterSpacing: "1.2px",
      textAlign: "center",
      color: "var(--col-navy-500)",
    },
    titleContainer: {
      display: "flex",
      paddingTop: "40px",
      paddingBottom: "30px",
      justifyContent: "center",
    },
    bodyContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 16px"
    },
    bodyDivider: {
      display: "flex",
      height: "0px",
      border: "1px solid rgb(91, 96, 99)",
      margin: "24px 0",
    },
    bodySubheadingRow: {
      display: "flex",
      alignItems: "center",
      gap: "16px",
      padding: "8px",
    },
    bodySubheading: {
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "27px",
      color: "var(--col-navy-500)",
    },
    bodyText: {
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "18px",
      color: "var(--col-navy-500)",
      padding: "8px",
    },
    appIcon: {
      height: "40px",
      width: "40px",
      minHeight: "40px",
      minWidth: "40px",
      borderRadius: "7px",
    },
    buttonRoot: {
      height: "34px",
    },
    logoContainer: {
      display: "flex",
      justifyContent: "center",
    },
    topBar: {
      display: "flex",
      justifyContent: "space-between",
      padding: "15px 10px 0 10px",
    },
  })
);

interface DeleteDataProps extends LocalizeContextProps {}

const DeleteDataPage: React.FC<DeleteDataProps> = ({ setActiveLanguage }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Translate>
      {({ translate }) => (
        <div>
          <div className={classes.topBar}>
            <Button
              classes={{ root: classes.buttonRoot }}
              onClick={() => {
                history.push("/");
              }}
            >
              <AvatarIcon color="var(--col-navy-500)"/>
            </Button>
            <MiniLogo />
          </div>
          <div className={classes.titleContainer}>
            <span className={classes.title}>
              {translate("deleteAccountPage.title").toString()}
            </span>
          </div>
          <div className={classes.bodyContainer}>
            <div style={{ width: "100%", maxWidth: "400px", paddingBottom: "40px" }}>
              <div>
                <div className={classes.bodySubheadingRow}>
                  <div className={classes.appIcon}>
                    <HomeAppIcon />
                  </div>
                  <span className={classes.bodySubheading}>
                    {translate("deleteAccountPage.homeAppTitle").toString()}
                  </span>
                </div>
                <div className={classes.bodyText}>
                  <ol
                    style={{
                      margin: "0",
                      paddingInlineStart: "2em",
                      lineHeight: "1.5em",
                    }}
                  >
                    <li>{translate("deleteAccountPage.homeAppStepOne").toString()}</li>
                    <li>{translate("deleteAccountPage.homeAppStepTwo").toString()}</li>
                    <li>{translate("deleteAccountPage.clickOnAccount").toString()}</li>
                    <li>{translate("deleteAccountPage.clickOnDeleteAccount").toString()}</li>
                    <li>{translate("deleteAccountPage.homeAppStepFive").toString()}</li>
                  </ol>
                  <p style={{ margin: "0", padding: "8px 0" }}>
                    {translate("deleteAccountPage.homeAppStepOptional").toString()}
                  </p>
                  <p style={{ margin: "0", padding: "8px 0" }}>
                    {translate("deleteAccountPage.homeAppFinalStep").toString()}
                  </p>
                </div>
                <div className={classes.bodySubheadingRow}>
                  <span className={classes.bodySubheading}>
                    {translate("deleteAccountPage.deletingYourData").toString()}
                  </span>
                </div>
                <div className={classes.bodyText}>
                  <p style={{ margin: "0", padding: "8px 0" }}>
                    {translate("deleteAccountPage.deletedDataTitle").toString()}
                  </p>
                  <ul
                    style={{
                      margin: "0",
                      paddingInlineStart: "2em",
                      lineHeight: "1.5em",
                    }}
                  >
                    <li>{translate("deleteAccountPage.firstAndLastName").toString()}</li>
                    <li>{translate("deleteAccountPage.email").toString()}</li>
                    <li>{translate("deleteAccountPage.telephone").toString()}</li>
                    <li>{translate("deleteAccountPage.customerAddress").toString()}</li>
                    <li>{translate("deleteAccountPage.customerProducts").toString()}</li>
                    <li>{translate("deleteAccountPage.usageData").toString()}</li>
                    <li>{translate("deleteAccountPage.historicalData").toString()}</li>
                    <li>{translate("deleteAccountPage.spokenLanguage").toString()}</li>
                    <li>{translate("deleteAccountPage.gender").toString()}</li>
                    <li>{translate("deleteAccountPage.servicePartner").toString()}</li>
                    <li>{translate("deleteAccountPage.waterQuality").toString()}</li>
                    <li>{translate("deleteAccountPage.calendar").toString()}</li>
                    <li>{translate("deleteAccountPage.password").toString()}</li>
                  </ul>
                </div>
              </div>
              <div className={classes.bodyDivider} />
              <div>
                <div className={classes.bodySubheadingRow}>
                  <div className={classes.appIcon}>
                    <ProAppIcon />
                  </div>
                  <span className={classes.bodySubheading}>
                    {translate("deleteAccountPage.proAppTitle").toString()}
                  </span>
                </div>
                <div className={classes.bodyText}>
                  <ol
                    style={{
                      margin: "0",
                      paddingInlineStart: "2em",
                      lineHeight: "1.5em",
                    }}
                  >
                    <li>{translate("deleteAccountPage.proAppStepOne").toString()}</li>
                    <li>{translate("deleteAccountPage.proAppStepTwo").toString()}</li>
                    <li>{translate("deleteAccountPage.clickOnAccount").toString()}</li>
                    <li>{translate("deleteAccountPage.clickOnDeleteAccount").toString()}</li>
                    <li>
                      {translate("deleteAccountPage.proAppStepFive").toString()}
                    </li>
                    <li>
                      {translate("deleteAccountPage.proAppStepSix").toString()}
                    </li>
                    <li>
                      {translate("deleteAccountPage.proAppStepSeven").toString()}
                    </li>
                  </ol>
                </div>
                <div className={classes.bodySubheadingRow}>
                  <span className={classes.bodySubheading}>
                    {translate("deleteAccountPage.deletingYourData").toString()}
                  </span>
                </div>
                <div className={classes.bodyText}>
                  <p style={{ margin: "0", padding: "8px 0" }}>
                    {translate("deleteAccountPage.deletedDataTitle").toString()}
                  </p>
                  <ul
                    style={{
                      margin: "0",
                      paddingInlineStart: "2em",
                      lineHeight: "1.5em",
                    }}
                  >
                    <li>{translate("deleteAccountPage.firstAndLastName").toString()}</li>
                    <li>{translate("deleteAccountPage.email").toString()}</li>
                    <li>{translate("deleteAccountPage.telephone").toString()}</li>
                    <li>{translate("deleteAccountPage.customerAddress").toString()}</li>
                    <li>{translate("deleteAccountPage.spokenLanguage").toString()}</li>
                    <li>{translate("deleteAccountPage.gender").toString()}</li>
                    <li>{translate("deleteAccountPage.b2cData").toString()}</li>
                    <li>{translate("deleteAccountPage.notifications").toString()}</li>
                    <li>{translate("deleteAccountPage.password").toString()}</li>
                  </ul>
                  <p style={{ margin: "0", padding: "8px 0" }}>
                    {translate("deleteAccountPage.notDeletedDataTitle").toString()}
                  </p>
                  <ul
                    style={{
                      margin: "0",
                      paddingInlineStart: "2em",
                      lineHeight: "1.5em",
                    }}
                  >
                    <li>{translate("deleteAccountPage.b2bData").toString()}</li>
                  </ul>
                </div>
              </div>
              <div className={classes.bodyDivider} />
              <div>
                <div className={classes.bodySubheadingRow}>
                  {/* <div className={classes.appIcon}>
                    <PearlWaterManagerAppIcon />
                  </div> */}
                  <span className={classes.bodySubheading}>
                    {translate("deleteAccountPage.pwmAppTitle").toString()}
                  </span>
                </div>
                <div className={classes.bodyText}>
                  <ol
                    style={{
                      margin: "0",
                      paddingInlineStart: "2em",
                      lineHeight: "1.5em",
                    }}
                  >
                    <li>{translate("deleteAccountPage.pwmAppStepOne").toString()}</li>
                    <li>{translate("deleteAccountPage.pwmAppStepTwo").toString()}</li>
                    <li>{translate("deleteAccountPage.clickOnDeleteAccount").toString()}</li>
                    <li>{translate("deleteAccountPage.pwmAppStepFour").toString()}</li>
                  </ol>
                  <p style={{ margin: "0", padding: "8px 0" }}>{translate("deleteAccountPage.pwmAppStepFive").toString()}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Translate>
  );
};

export default withLocalize(DeleteDataPage);
