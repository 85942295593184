import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  CardContent,
  Card,
  CardHeader,
  CircularProgress,
} from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { resendConfirmationEmail, refreshAntiforgery } from "../utils/accountAPI";
import { useHistory } from "react-router-dom";
import { sharedStyles } from "../themes/sharedStyles";
import { useAuthContext } from "../Providers/authProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    container: {
      display: "flex",
      flexWrap: "wrap",
      margin: `${theme.spacing(0)} auto`,
      justifyContent: "center",
    },
    cardHeader: {
      fontSize: "35px",
      fontWeight: "bold",
      lineHeight: "1.67",
      textAlign: "left",
      color: "#5f6062",
      [theme.breakpoints.only("xs")]: {
        fontSize: "26px",
      },
    },
    headerRoot: {
      paddingBottom: "10px",
      padding: "0px"
    },
    centeredContainer: {
      width: theme.spacing(50),
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "40px",
    },
  })
);
interface IResendEmailProps {
  email: string;
}
interface IResendEmailState {
  status: "send" | "sending" | "failed";
}
export const ResendEmail: React.FC<IResendEmailProps> = ({ email }) => {
  const history = useHistory();
  const classes = useStyles();
  const { setAuthState } = useAuthContext();
  useEffect(() => {
    resendConfirmationEmail()
      .then(
        (res: any) => {
          if (res.data === "Email sent") {
            setState({ status: "send" });
            return;
          }
          if (res.data.ErrorCode === "1101") {
            setState({ status: "failed" });
            return;
          }
          if (res.data.errorCode === "1102") {
            history.push("/error?code=RE-003");
            return;
          }
        },
        (rej: any) => {
          if (rej.response.status === 401) {
            setAuthState({ isAuth: false });
            refreshAntiforgery();
            history.push("/login");
            return;
          }
          history.push("/error?code=RE-001");
        }
      )
      .catch(() => {
        history.push("/error?code=RE-002");
      });
  }, [history, setAuthState]);
  const [state, setState] = useState<IResendEmailState>({ status: "sending" });
  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div>
            {state.status === "sending" && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}
            {state.status === "send" && (
              <div className={classes.container}>
                <Card className={classes.card}>
                  <CardHeader
                    classes={{
                      title: classes.cardHeader,
                      root: classes.headerRoot,
                    }}
                    title={translate("resendEmail.emailResend")}
                  />
                  <CardContent style={{ padding: "0px" }}>
                    <div className={classes.centeredContainer}>
                      <p>
                        {translate("resendEmail.emailResendText", {
                          email: email,
                        })}
                      </p>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )}
            {state.status === "failed" && (
              <div className={classes.container}>
                <Card className={classes.card}>
                  <CardHeader
                    classes={{
                      title: classes.cardHeader,
                      root: classes.headerRoot,
                    }}
                    title={translate("resendEmail.emailResend")}
                  />
                  <CardContent style={{ padding: "0px" }}>
                    <div className={classes.centeredContainer}>
                      <p>
                        {translate("resendEmail.emailResendText", {
                          email: email,
                        })}
                      </p>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )}
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
