import { Theme } from "@material-ui/core/styles";
export const sharedStyles = (theme: Theme) => ({
  card: {
    borderRadius: "0px",
    padding: `8px 80px 20px`,
    marginTop: theme.spacing(2),
    boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 1px #e1e3e4",
    [theme.breakpoints.only("xs")]: {
      boxShadow: "none",
      border: "none",
      marginTop: theme.spacing(2),
      width: "100%",
      padding: "5px 5vw 0px",
    },
  },
  gridRow1: {
    "-ms-grid-row": 1,
  },
  gridRow2: {
    "-ms-grid-row": 2,
  },
  gridRow3: {
    "-ms-grid-row": 3,
  },
  gridRow4: {
    "-ms-grid-row": 4,
  },
  gridRow5: {
    "-ms-grid-row": 5,
  },
  gridRow6: {
    "-ms-grid-row": 6,
  },
  gridRow7: {
    "-ms-grid-row": 7,
  },
  gridRow8: {
    "-ms-grid-row": 8,
  },
  gridRow9: {
    "-ms-grid-row": 9,
  },
  gridRow10: {
    "-ms-grid-row": 10,
  },
});
