import React from "react";

export enum RegistrationSteps {
  Initial,
  Details,
}

export interface RegisterState {
  registerStep: RegistrationSteps;
  email: string;
  password: string;
  gender: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  address: string;
  postcode: string;
  location: string;
  country: string;
  prefLanguage: string;
  emailContact: boolean;
  returnUrl: string;
}

interface RegisterContextState {
  state: RegisterState;
  setRegisterState: (value: Partial<RegisterState>) => void;
  resetState: () => void;
}
const registerStateDefault: RegisterState = {
  registerStep: RegistrationSteps.Initial,
  email: "",
  password: "",
  gender: "",
  lastName: "",
  firstName: "",
  phoneNumber: "",
  address: "",
  postcode: "",
  location: "",
  country: "",
  prefLanguage: "",
  emailContact: false,
  returnUrl: "",
};
export const RegisterContext = React.createContext<RegisterContextState>({
  state: registerStateDefault,
  setRegisterState: () => {},
  resetState: () => {},
});

const RegisterProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<RegisterState>(registerStateDefault);

  const setRegisterState = (value: Partial<RegisterState>) => {
    setState({ ...state, ...value });
  };

  const resetState = () => {
    setState(registerStateDefault);
  };

  return (
    <RegisterContext.Provider value={{ setRegisterState, state, resetState }}>
      {children}
    </RegisterContext.Provider>
  );
};

export function useRegisterContext(): RegisterContextState {
  const { state, setRegisterState, resetState } = React.useContext(
    RegisterContext
  );
  return { state, setRegisterState, resetState };
}

export default RegisterProvider;
