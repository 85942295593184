import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import bwtLogo from "../resources/BWT.svg";


const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    logoContainer: {
      display: "flex",
      paddingTop: theme.spacing(1),
      justifyContent: "center",
      [theme.breakpoints.only('xs')]: {
        paddingTop: theme.spacing(0),
      },
    }, logo: {
      width: "130px",
      height: "min-intrinsic",
      paddingTop: "10px",
      [theme.breakpoints.only('xs')]: {
        width: "100px",
        paddingRight: theme.spacing(3),
      },
    },
    allignCard: {
      width: "520px",
      display: "flex",
      justifyContent: "flex-end"
    }
  });
});
export const Logo: React.FC = props => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.logoContainer}>
        <div className={classes.allignCard} >
          <img className={classes.logo} src={bwtLogo} alt=""></img>
        </div>
      </div>
    </React.Fragment>
  );
};
