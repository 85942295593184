import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Translate } from "react-localize-redux";
import { CardContent, Card } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    errorCard: {
      marginTop: theme.spacing(6),
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      boxShadow: "none",
      [theme.breakpoints.only("xs")]: {
        marginTop: theme.spacing(0),
      },
    },
    errorCardContentRoot: {
      paddingBottom:theme.spacing(2),
      paddingTop:theme.spacing(2),
      width:theme.spacing(60),
      display: "flex",
      border: "solid 1px ",
      boxShadow: "0px 0px 6px var(--col-accent-magnesium-500)",
      borderColor: "var(--col-accent-magnesium-500)",
      margin:theme.spacing(1),
      [theme.breakpoints.only("xs")]: {
        width: "100%",
        margin:theme.spacing(2)
      },
    },
    warningIcon: {
      width: theme.spacing(5),
      alignSelf: "center",
      height: "3em",
      color: "var(--col-accent-magnesium-500)",
    },
    warningTextContainer: {
      width: "100%",
      padding: "0px 12px",
    },
    errorHeader: {
      fontSize: "24px",
      lineHeight: "1.33",
      letterSpacing: "0.53px",
      display: "flex",
      fontWeight:"bold",
      color: "var(--col-accent-magnesium-500)",
      [theme.breakpoints.only("xs")]: {
        fontSize: "18px",
      },
    },
    errorBody: {
      fontSize: "17px",
      lineHeight: "1.33",
      letterSpacing: "0.45px",
      display: "flex",
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
      },
    },
  });
});
interface ErrorCardProps {
  text: string;
  header?:string;
  textProps?:any;
}
export const ErrorCard: React.FC<ErrorCardProps> = ({ text,header,textProps}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <Card className={classes.errorCard}>
            <CardContent className={classes.errorCardContentRoot}>
              <WarningIcon className={classes.warningIcon}></WarningIcon>
              <div className={classes.warningTextContainer}>
                <span className={classes.errorHeader}>
                  {translate(header??"landing.thereWasAProblem")}
                </span>
                <span className={classes.errorBody}>
                  {translate(text,textProps,{ renderInnerHtml: true })}
                </span>
              </div>
            </CardContent>
          </Card>
        )}
      </Translate>
    </React.Fragment>
  );
};
