import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { useLoginContext } from "../Providers/loginProvider";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../Providers/authProvider";
import { changeContactMe, refreshAntiforgery } from "../utils/accountAPI";
import { useForm } from "react-hook-form";
import { BwtButton, BwtButtons } from "./BwtButton";



const useStyles = makeStyles((theme:Theme) => {
    return createStyles({
      title: {
        fontSize: "18px",
        textAlign: "left",
        fontWeight: "bold",
        lineHeight: "1.67",
        color: "var(--col-navy-500)",
      },
      body: {
        display: "flex",
        justifyContent: "center",
      },
      container:{
        padding:theme.spacing(5),
        [theme.breakpoints.only("xs")]: {
          paddingBottom:"12px",
          paddingTop: "30px",
        },
      },
      infoMsg: {
        fontSize: "14px",
        lineHeight: "1.29",
        letterSpacing: "0.35px",
        color: "var(--col-navy-500)",
      },
      textContainer: {
        paddingBottom: "15px",
      },
      actionContainer: {
        paddingTop: "38px",
        width: theme.spacing(40),
        [theme.breakpoints.only("xs")]: {
          width:"100%"
        },
      },
      checkboxLabel: {
        width: theme.spacing(40),
        marginBottom: "15px",
        [theme.breakpoints.only("xs")]: {
          width: "100%",
        },
      },
      checkBoxRoot: {
        alignSelf: "start",
        color: "var(--col-navy-500)",
      },
    });
  });

interface IChangeEmailPreferencesProps{
    canContact: boolean;
    setCanContact: (canContact: boolean) => void;
}

interface IChangeEmailPreferencesState {
    checked: boolean
}

export const ChangeEmailPreferences: React.FC<IChangeEmailPreferencesProps> = ({
    canContact,
    setCanContact,
}) =>{

    const classes = useStyles();
    const loginContext = useLoginContext();
    const history = useHistory();
    const { setAuthState } = useAuthContext();
    const { handleSubmit } = useForm({});

    const [state, setState] = useState<IChangeEmailPreferencesState>({
        checked: canContact,
    });

    const handleUpdate = () => {
        changeContactMe(state.checked)
        .then((res:any) => {
            const errorCode = res?.data?.errorCode;
            if(errorCode){
                handErrorResponse(errorCode);
                return;
            }
            setCanContact(state.checked);
            history.goBack()
        },
        (rej:any) =>{
            if(rej.response.staus === 401){
                setAuthState({isAuth: false});
                refreshAntiforgery();
                history.push("/login",{email:loginContext.state.email});
                return;
            }
        })
        .catch((error:any) =>{
            history.push("/error?code=CE-002");
        })
    };
    const handErrorResponse = (errorCode: string) =>{
        if (errorCode === "9999") {
            history.push("/error");
            return;
        }	
    }

    return (
        <React.Fragment>
            <Translate>
                {({ translate }) =>(
                    <div className={classes.body}>
                        <div className={classes.container}>
                            <form
                                onSubmit={handleSubmit((data) => handleUpdate())}
                            >
                            <span className={classes.title}>
                                {translate("profile.newsletter")}
                            </span>
                            <div style={{ paddingTop: "30px", color: "var(--col-navy-500)" }}>
                                <FormControlLabel
                                    className={classes.checkboxLabel}
                                    control={
                                        <Checkbox
                                            classes={{ root: classes.checkBoxRoot }}
                                            color="secondary"
                                            checked={state.checked}
                                            onChange={() => setState({checked: !state.checked})}
                                            name="canContactMe"
                                        />
                                    }
                                    label={translate("profile.newsletterDetails")}
                                />
                            </div>
                                <BwtButton
                                    label={translate("profile.saveChanges").toString()}
                                    styleType={BwtButtons.pink}
                                    type="submit"
                                    disabled={false}
                                ></BwtButton>
                            
                            </form>
                        </div>
                    </div>
                )}
            </Translate>
        </React.Fragment>
    )
}