import * as React from "react";
import { SVGProps } from "react";
const HomeAppIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={39.492} height={39.492} fill="url(#a)" rx={7.021} />
    <path
      fill="#fff"
      d="m26.645 14.938-14.2-7.975v2.53L8.47 7.383V23.37a9.366 9.366 0 0 0 12.392 8.877c.399.058.802.084 1.205.08a9.3 9.3 0 0 0 4.58-17.39Zm-8.796 16.37a7.945 7.945 0 0 1-7.954-7.956V9.694l4.438 2.472v11.186a3.515 3.515 0 1 0 4.5-3.374v-4.5a7.943 7.943 0 0 1-.984 15.83Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={39.492}
        x2={0}
        y1={39.492}
        y2={0}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0B6EB2" />
        <stop offset={1} stopColor="#84B3D4" />
      </linearGradient>
    </defs>
  </svg>
);
export default HomeAppIcon;
