import { FieldError } from "react-hook-form";

interface ErrorDefinition {
  errorCode: string;
  errorDescription: string;
}
export const ErrorDefinitions: ErrorDefinition[] = [
  { errorCode: "AM-001", errorDescription: "logoutFailed" },
  { errorCode: "CP-003", errorDescription: "tooManyEmails" },
  { errorCode: "CE-003", errorDescription: "tooManyEmails" },
  { errorCode: "RE-003", errorDescription: "tooManyEmails" },
  { errorCode: "FPB-003", errorDescription: "tooManyEmails" },
];

export const getErrors = (error: FieldError | undefined) => {
  const errors = error?.message?.toString()?.split(";");
  if (error?.type === "required") {
    return "empty";
  }
  return errors;
};
