import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { FieldError, NestDataObject } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioRoot: {
      color: "var(--col-navy-500)",
    },
    radioRootError: {
      color: "var(--col-accent-magnesium-500)",
    },
    errorLabel: {
      color: "var(--col-accent-magnesium-500)",
      fontSize: "12px",
      display: "flex",
    },
  })
);

interface GenderSelectionProps {
  handleGenderChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: NestDataObject<Record<string, any>, FieldError>;
  value?: string;
}

export const GenderSelection: React.FC<GenderSelectionProps> = ({
  handleGenderChange,
  errors,
  value,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div style={{ color: "var(--col-navy-500)"}}>
            <RadioGroup
              row
              aria-label="gender"
              name="gender"
              onChange={handleGenderChange}
            >
              <FormControlLabel
                value="Female"
                control={
                  <Radio
                    checked={value === "Female"}
                    className={`${classes.radioRoot} ${
                      errors?.["gender"] ? classes.radioRootError : ""
                    }`}
                    color="secondary"
                  />
                }
                label={translate("register.Ms/Mrs")}
              />
              <FormControlLabel
                value="Male"
                control={
                  <Radio
                    checked={value ==="Male"}
                    className={`${classes.radioRoot} ${
                      errors?.["gender"] ? classes.radioRootError : ""
                    }`}
                    color="secondary"
                  />
                }
                label={translate("register.Mr")}
              />
            </RadioGroup>
            {errors?.["gender"] && (
              <span className={classes.errorLabel}>
                {translate("validation.required")}
              </span>
            )}
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
