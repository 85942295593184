import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Translate } from "react-localize-redux";
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { logout, refreshAntiforgery } from "../utils/accountAPI";
import { useAuthContext } from "../Providers/authProvider";
import AvatarIcon from "../resources/AvatarIcon";

const useStyles = makeStyles(() => {
  return createStyles({
    buttonRoot: { position: "absolute", left: "10px", top: "15px" },
  });
});
interface IAvatarMenuProps {
  setLoadingState?: () => void;
  setIframe?: (url: string) => void;
}
export const AvatarMenu: React.FC<IAvatarMenuProps> = ({ setLoadingState, setIframe }) => {
  const classes = useStyles();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [state, setState] = React.useState({ open: false, iframeUrl: "" });
  const { authState, setAuthState } = useAuthContext();
  const history = useHistory();

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setState({ ...state, open: false });
  };
  const handleLogout = (event: React.MouseEvent<EventTarget>) => {
    handleClose(event);
    if (setLoadingState) setLoadingState();
    refreshAntiforgery().finally(() => {
      logout(null).then(
        (res: any) => {
          if (setIframe && res.data.signOutIframeUrl) setIframe(res.data.signOutIframeUrl);
          setTimeout(() => {
            setAuthState({ isAuth: false });
            const redirectUrl = res?.data?.redirectUrl;
            if (redirectUrl) {
              window.location.href = redirectUrl;
            } else {
              window.location.href = "/";
            }
          }, 2000);
        },
        (rej: any) => {
          history.push("/error?code=AM-001");
        }
      );
    });
  };
  const handleProfile = (event: React.MouseEvent<EventTarget>) => {
    handleClose(event);
    history.push("/profile");
  };
  const handleLogin = (event: React.MouseEvent<EventTarget>) => {
    handleClose(event);
    history.push("/");
  };
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setState({ ...state, open: true });
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(state.open);
  React.useEffect(() => {
    if (prevOpen.current === true && state.open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = state.open;
  }, [state.open]);
  const handleToggle = () => {
    setState({ ...state, open: !prevOpen.current })
  };
  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div>
            <Button
              classes={{ root: classes.buttonRoot }}
              ref={anchorRef}
              aria-controls={state.open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <AvatarIcon color="var(--col-navy-500)"/>
            </Button>
            <Popper
              open={state.open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={state.open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        {authState.isAuth && (
                          <MenuItem onClick={handleProfile}>
                            {translate("profile.profile")}
                          </MenuItem>
                        )}
                        {authState.isAuth && (
                          <MenuItem onClick={handleLogout}>
                            {translate("profile.logout")}
                          </MenuItem>
                        )}
                        {!authState.isAuth && (
                          <MenuItem onClick={handleLogin}>
                            {translate("profile.login")}
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
