interface language {
  code: string;
  name: string;
  nativeName: string;
}

const SupportedLanguages: language[] = [
  { code: "de", name: "German", nativeName: "Deutsch" },
  { code: "en", name: "English", nativeName: "English" },
  { code: "it", name: "Italian", nativeName: "Italiano" },
  { code: "fr", name: "French", nativeName: "Français" },
  { code: "nl", name: "Dutch", nativeName: "Nederlands, Vlaams" },
  { code: "da", name: "Danish", nativeName: "Dansk" },
  { code: "hu", name: "Hungarian", nativeName: "Magyar" },
  { code: "pl", name: "Polish", nativeName: "Polski" },
  { code: "uk", name: "Ukrainian", nativeName: "Yкраїнська" },
  { code: "ru", name: "Russian", nativeName: "Pусский язык" },
  { code: "ro", name: "Romanian",nativeName: "Română"},
  { code: "sv", name: "Swedish", nativeName: "Svenska" },
  { code: "no", name: "Norwegian", nativeName: "Norsk" },
  { code: "fi", name: "Finnish", nativeName: "Suomi, Suomen kieli" },
  { code: "es", name: "Spanish", nativeName: "Español" },
  { code: "zh", name: "Chinese", nativeName: "中文 (Zhōngwén)" },
  { code: "hr", name: "Croatian", nativeName: "Hrvatski"},
  { code: "si", name: "Slovenian", nativeName: "Slovenščina"},
  { code: "pt", name: "Portuguese", nativeName: "Português"},
];


export const SupportedLanguageCodes = SupportedLanguages
  .sort((a, b) => a.nativeName.localeCompare(b.nativeName))
  .map(language => language.code);

export const getNativeName = (langCode: string) => SupportedLanguages.find(lang => lang.code === langCode)?.nativeName ?? '';
