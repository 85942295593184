import React from "react";
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Register } from "./Pages/register";
import { ErrorPage } from "./Pages/error";
import { Logout } from "./Pages/logout";
import LandingPage from "./Pages/landing";
import LoginProvider from "./Providers/loginProvider";
import RegisterProvider from "./Providers/registerProvider";
import AuthProvider from "./Providers/authProvider";
import { ProfilePage } from "./Pages/profile";
import { ForgotPassPage } from "./Pages/forgotPassword";
import { EmailConfirmPage } from "./Pages/confirmEmail";
import { NotFoundPage } from "./Pages/notFound";
import axios from 'axios';
import deleteDataPage from "./Pages/deleteData";
import AppTermsAndConditionsPage from "./Pages/appTermsAndConditions"

axios.defaults.headers['Pragma'] = 'no-cache';
function App() {
  return (
    <BrowserRouter>
      <LoginProvider>
        <RegisterProvider>
          <AuthProvider>
            <Switch>
              <Route path="/register" component={() => <Register />} />
              <Route path="/logout" component={Logout} />
              <Route path="/error" component={ErrorPage} />
              <Route path="/profile" component={ProfilePage} />
              <Route path="/forgotPassword" component={ForgotPassPage} />
              <Route path="/DeleteBWTUserAccount" component={deleteDataPage} />
              <Route path="/AppTermsAndConditions" component={AppTermsAndConditionsPage} />
              <Route path="/EmailConfirmed" component={EmailConfirmPage} />
              <Route exact path="/" component={() => <LandingPage />} />
              <Route component={NotFoundPage} />
            </Switch>
          </AuthProvider>
        </RegisterProvider>
      </LoginProvider>
    </BrowserRouter>
  );
}

export default App;
