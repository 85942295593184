import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  CardContent,
  Card,
  CardHeader,
  CardActions,
  Button,
} from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { sharedStyles } from "../themes/sharedStyles";
import { BwtButton, BwtButtons } from "./BwtButton";
import { useHistory } from "react-router-dom";
import { onBackToHomepage, onBackToRedirect } from "../utils/linkToDocs";
import { useUrlQuery } from "../utils/useUrlQuery";
import { getReturnUrl } from "../utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    container: {
      display: "flex",
      flexWrap: "wrap",
      margin: `${theme.spacing(0)} auto`,
      justifyContent: "center",
    },
    cardHeader: {
      fontSize: "35px",
      fontWeight: "bold",
      lineHeight: "1.67",
      textAlign: "left",
      color: "#5f6062",
      display: "flex",
      justifyContent: "center",
    },
    headerRoot: {
      paddingBottom: "10px",
    },
    centeredContainer: {
      width: theme.spacing(40),
      display: "flex",
      justifyContent: "end",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    textMargin: {
      margin: theme.spacing(2),
    },
    capitalizeButton: {
      textTransform: "capitalize",
      boxShadow: "none",
      height: "50px",
      width: "320px",
      marginTop: "16px",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
  })
);

export const EmailSent: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const onBackToAccount = () => {
    history.push("/");
  };
  const returnUrl =
    useUrlQuery().get("ReturnUrl") || localStorage.getItem("returnUrl");
  const redirect = getReturnUrl(returnUrl);
  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div className={classes.container}>
            <Card className={classes.card}>
              <CardHeader
                classes={{
                  title: classes.cardHeader,
                  root: classes.headerRoot,
                }}
                title={translate("forgotPassword.emailSent")}
              />
              <CardContent style={{ padding: "0px" }}>
                <div className={classes.centeredContainer}>
                  <p className={classes.textMargin}>
                    {translate("forgotPassword.weHaveSentEmail")}
                  </p>
                </div>
                <div className={classes.centeredContainer}>
                  <p className={classes.textMargin}>
                    {translate("forgotPassword.followInstrucations")}
                  </p>
                </div>
              </CardContent>
              <CardActions>
                <div style={{ display: "grid", width: "100%" }}>
                  {returnUrl && redirect && (
                    <BwtButton
                      styleType={BwtButtons.pink}
                      type="button"
                      onClick={() => onBackToRedirect(redirect)}
                      label={
                        translate("resendEmail.backTo").toString() + redirect
                      }
                    ></BwtButton>
                  )}
                  {(!returnUrl || !redirect) && (
                    <BwtButton
                      styleType={BwtButtons.pink}
                      type="button"
                      onClick={() => onBackToHomepage()}
                      label={translate("resendEmail.backToHomepage").toString()}
                    ></BwtButton>
                  )}

                  <Button
                    style={{ marginLeft: "0px" }}
                    classes={{ root: classes.capitalizeButton }}
                    onClick={() => onBackToAccount()}
                  >
                    {translate("resendEmail.backToAccount")}
                  </Button>
                </div>
              </CardActions>
            </Card>
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
