import { combineReducers, createStore } from 'redux';

import { localizeReducer } from "react-localize-redux";

const rootReducer = combineReducers({
  localize: localizeReducer
});

export type AppState = ReturnType<typeof rootReducer>

export default createStore(
  rootReducer,
);

