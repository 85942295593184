import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Translate, TranslateFunction } from "react-localize-redux";
import {  Theme } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { SimpleInput } from "./SimpleInput";
import { BwtButton, BwtButtons } from "./BwtButton";
import { updateEmail, refreshAntiforgery } from "../utils/accountAPI";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../Providers/authProvider";
import { useLoginContext } from "../Providers/loginProvider";

const useStyles = makeStyles((theme:Theme) => {
  return createStyles({
    title: {
      fontSize: "18px",
      textAlign: "left",
      fontWeight: "bold",
      lineHeight: "1.67",
      color: "var(--col-navy-500)",
    },
    body: {
      display: "flex",
      justifyContent: "center",
    },
    container:{
      padding:theme.spacing(5),
      [theme.breakpoints.only("xs")]: {
        paddingBottom:"12px",
        paddingTop: "30px",
      },
    },
    infoMsg: {
      fontSize: "14px",
      lineHeight: "1.29",
      letterSpacing: "0.35px",
      color: "var(--col-navy-500)",
    },
    textContainer: {
      paddingBottom: "15px",
    },
    actionContainer: {
      paddingTop: "38px",
      width: theme.spacing(40),
      [theme.breakpoints.only("xs")]: {
        width:"100%"
      },
    },
  });
});
interface IChangeEmailState {
  disableButton: boolean;
}
interface IChangeEmailProps {
  setEmail: (email: string) => void;
  setEmailConfirmation:(isConfirmed:boolean) =>void;
}

export const ChangeEmail: React.FC<IChangeEmailProps> = ({
  setEmail,
}) => {
  const { setAuthState } = useAuthContext();
  const classes = useStyles();
  const history = useHistory();
  const loginContext = useLoginContext();
  const { handleSubmit, control, errors, setError } = useForm({});
  const [state, setState] = useState<IChangeEmailState>({
    disableButton: false,
  });

  const handleUpdate = (
    data: Record<string, any>,
    translate: TranslateFunction
  ) => {
    setState({ disableButton: true });
    updateEmail(data["newEmail"])
      .then(
        (res: any) => {
          const errorCode = res?.data?.errorCode;
          if (errorCode) {
            handleErrorResponse(errorCode, translate);
            setState({ disableButton: false });
            return;
          }
          setEmail(data["newEmail"]);
          history.goBack();
        },
        (rej: any) => {
          if (rej.response.data.errors) {
            handleServerSideValidationError(
              rej.response.data.errors,
              translate
            );
            setState({ disableButton: false });
            return;
          }
          if (rej.response.status === 401) {
            setAuthState({ isAuth: false });
            refreshAntiforgery();
            history.push("/login",{email:loginContext.state.email});
            return;
          }
          history.push("/error?code=CE-001");
        }
      )
      .catch((error: any) => {
        history.push("/error?code=CE-002");
      });
  };

  const handleServerSideValidationError = (
    errors: any,
    translate: TranslateFunction
  ) => {
    if (errors.Email) {
      setError(
        "newEmail",
        {message:translate("profile.invalidEmail").toString(),type:"serverSide"}
      );
    }
  };

  const handleErrorResponse = (
    errorCode: string,
    translate: TranslateFunction
  ) => {
    if (errorCode === "1100") {
      setError(
        "newEmail",
        {message:translate("profile.emailInUse").toString(),type:"serverSide"}
      );
      return;
    }
    if (errorCode === "1102") {
      history.push("/error?code=CE-003");
      return;
    }
    if (errorCode === "9999") {
      history.push("/error");
      return;
    }
  };
  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div className={classes.body}>
            <div
              className={
                classes.container
              }
            >
              <form
                autoComplete="on"
                onSubmit={handleSubmit((data) => handleUpdate(data, translate))}
              >
                <span className={classes.title}>
                  {translate("profile.changeEmail")}
                </span>
                <div className={classes.actionContainer}>
                  <div className={classes.textContainer}>
                    <span className={classes.infoMsg}>
                      {translate("profile.emailInfoMsg")}
                    </span>
                  </div>
                  <SimpleInput
                    id="newEmail"
                    type="email"
                    label={translate("profile.newEmailAddress") as string}
                    control={control}
                    required={true}
                    errors={errors}
                    autocomplete="email"
                  ></SimpleInput>
                </div>
                <BwtButton
                  label={translate("profile.saveChanges").toString()}
                  styleType={BwtButtons.pink}
                  type="submit"
                  disabled={state.disableButton}
                ></BwtButton>
              </form>
            </div>
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
