import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  CardContent,
  Button,
  CardActions,
  CardHeader,
} from "@material-ui/core";
import { Translate, TranslateFunction } from "react-localize-redux";
import { SimpleInput } from "./SimpleInput";
import { MaskedInput } from "./MaskedInput";
import { useHistory } from "react-router-dom";
import { useRegisterContext } from "../Providers/registerProvider";
import { useForm } from "react-hook-form";
import {
  atLeastXLong,
  containsLetter,
  containsSpecialCharecter,
  containsNumber,
} from "../utils/regexp";
import { initValidation } from "../utils/accountAPI";
import { BwtButton, BwtButtons } from "./BwtButton";
import { PasswordDescription } from "./PasswordDescription";
import { getErrors } from "../utils/errors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      margin: `${theme.spacing(0)} auto`,
      justifyContent: "center",
    },
    loginBtn: {
      marginTop: theme.spacing(1),
      flexGrow: 1,
      borderRadius: "50px",
      height: "50px",
    },
    card: {
      borderRadius: "0px",
      padding: "3vh 8vw;",
      marginTop: theme.spacing(20),
    },
    input: {
      width: "16vw",
      minWidth: "200px",
      border: "solid 1px",
      padding: "0px 15px",
      marginBottom: "15px",
    },
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    passwordMask: {
      letterSpacing: "2px",
      fontWeight: "bold",
    },
    textFieldRoot: {
      width: "100%",
    },
    centeredButton: {
      marginBottom: "14px",
    },
    shallowButton: {
      fontSize: "14px",
      fontWeight: 300,
      color: "#b3b0b0",
    },
    capitalizeButton: {
      textTransform: "capitalize",
      boxShadow: "none",
      width: "20vw",
      height: "50px",
      maxWidth: "320px",
      minWidth: "200px",
    },
    centeredContainer: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "15px",
      textAlign: "center",
    },
    header: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "1.67",
      color: "var(--col-navy-500)",
    },
  })
);
interface IRegisterInitialStepProps {
  setErrorContainer: (
    active: boolean,
    message: string,
    header?: string
  ) => void;
}
export const RegisterInitialStep: React.FC<IRegisterInitialStepProps> = ({
  setErrorContainer,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [initStepState, setinitStepState] = useState({
    submitting: false,
    passServerSideError: false,
  });
  const { state, setRegisterState } = useRegisterContext();
  const { handleSubmit, control, errors, setError, watch } = useForm({
    defaultValues: { email: state.email, password: state.password },
  });
  const passErrors = getErrors(errors["password"]);
  useEffect(() => {
    control.triggerValidation("password");
  }, [control]);
  const handleErrorCodes = (errorCode: string) => {
    if (errorCode === "1000") {
      setErrorContainer(
        true,
        "register.emailInUse",
        "register.doYouHaveAnAccount"
      );
    }
    if (errorCode === "1001") {
      setinitStepState({ ...initStepState, passServerSideError: true });
    }
  };
  const handleLogin = (
    data: Record<string, any>,
    translate: TranslateFunction
  ) => {
    initValidation(data["email"], data["password"])
      .then(
        (res: any) => {
          if (res.data?.errorCode) {
            handleErrorCodes(res.data?.errorCode);
            return;
          }
          setErrorContainer(
            false,
            "register.emailInUse",
            "register.doYouHaveAnAccount"
          );
          setRegisterState({
            email: data["email"],
            password: data["password"],
          });
          history.push("/register/details");
        },
        (rej: any) => {
          if (rej.response.data.errors?.Email) {
            setError(
              "email",
              "serverSideError",
              translate("register.notValidEmail").toString()
            );
            return;
          }

          history.push("/error?code=RIS-001");
        }
      )
      .catch(() => {
        history.push("/error?code=RIS-002");
      });
  };
  const onCancel = () => {
    history.push("/");
  };

  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <form
            autoComplete="on"
            onSubmit={handleSubmit((data) => handleLogin(data, translate))}
          >
            <CardHeader
              title={translate("register.welcome")}
              classes={{ title: classes.header }}
            />
            <CardContent>
              <div>
                <SimpleInput
                  id={"email"}
                  type="email"
                  label={translate("login.email") as string}
                  control={control}
                  required={true}
                  errors={errors}
                  autoFocus={true}
                  autocomplete="email"
                ></SimpleInput>
                <MaskedInput
                  onchange={([selected]) => {
                    setTimeout(() => {
                      control.triggerValidation("password", true);
                    }, 20);
                    return selected;
                  }}
                  id="password"
                  label={translate("login.password") as string}
                  value={watch("password", "")}
                  allowShow={true}
                  control={control}
                  errors={errors}
                  required={true}
                  showErrorMsg={false}
                  serverSideError={initStepState.passServerSideError}
                  showErrorBorder={false}
                  regexValidation={[
                    containsLetter,
                    containsNumber,
                    containsSpecialCharecter,
                    atLeastXLong,
                  ]}
                ></MaskedInput>
                <PasswordDescription
                  passErrors={passErrors}
                  control={control}
                ></PasswordDescription>
              </div>
            </CardContent>
            <CardActions style={{ paddingTop: "4vh", padding: "0px 16px" }}>
              <BwtButton
                styleType={BwtButtons.pink}
                type="submit"
                label={translate("login.next").toString()}
                disabled={initStepState.submitting}
              ></BwtButton>
            </CardActions>
            <div className={classes.centeredContainer}>
              <span>{translate("register.dontWantAccount")}</span>
            </div>
            <div className={classes.centeredContainer}>
              <Button
                onClick={() => onCancel()}
                classes={{ root: classes.capitalizeButton }}
              >
                {translate("login.cancel")}
              </Button>
            </div>
          </form>
        )}
      </Translate>
    </React.Fragment>
  );
};
