import Axios from "axios";
import { getCookie } from "../utils/getCookie";


const profileMapper = (data: any) => {
  const responseData: ProfileDto = {
    Email: data.email,
    FirstName: data.firstName,
    LastName: data.lastName,
    Street: data.street,
    PhoneNumber: data.phoneNumber,
    PreferredLanguage: data.preferredLanguage,
    CountryCode: data.countryCode,
    City: data.city,
    PostalCode: data.postalCode,
    Gender: data.gender,
    EmailConfirmed: data.emailConfirmed,
    isPasswordSet: data.isPasswordSet,
    canContact: data.canContact,
    AdacNumber: data.adacNumber
  };
  return responseData;
}

export const validateAdacNumber = (adacNumber: string, email: string) => 
  Axios.post(
    "/api/frontend/registration/ValidateAdacNumber",
    { AdacNumber: adacNumber, Email: email },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
      },
    }
  )

export const login = (
  username: string,
  password: string,
  RememberLogin: boolean,
  ReturnUrl: string
) =>
  Axios.post(
    "/api/frontend/account/login",
    {
      email: username,
      password: password,
      RememberLogin: RememberLogin,
      ReturnUrl: ReturnUrl,
    },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
      },
    }
  ).then(resp => {
    const ret: { issue?: 'Profile missing', profile?: ProfileDto, redirectUrl?: string, status?: string, errorCode?: string } = {};

    if (resp.data?.redirectUrl) { ret.redirectUrl = resp.data.redirectUrl }
    if (resp.data?.status) { ret.status = resp.data.status }
    if (resp.data?.errorCode) { ret.errorCode = resp.data?.errorCode }

    if (resp.data?.issue === 'Profile missing') {
      ret.issue = 'Profile missing';
    } else {
      ret.profile = profileMapper(resp.data.profile);
    }

    return ret;
  });

export interface RegisterModel {
  Email: string;
  Password: string;
  AcceptedTermsOfService: boolean;
  AcceptedMarketingUsage: boolean;
  ReturnUrl: string;
  Gender: string;
  LastName: string;
  FirstName: string;
  PhoneNumber: string;
  PostalCode: string;
  Street: string;
  City: string;
  CountryCode: string;
  PreferredLanguage: String;
  AdacNumber: string;
}
export const register = (model: RegisterModel) => 
  Axios.post("/api/frontend/registration", model, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
    },
  });




export interface RegisterExternalModel {
  AcceptedTermsOfService: boolean;
  AcceptedMarketingUsage: boolean;
  ReturnUrl: string;
  Gender: string;
  LastName: string;
  FirstName: string;
  PhoneNumber: string;
  PostalCode: string;
  Street: string;
  City: string;
  CountryCode: string;
  PreferredLanguage: String;
  AdacNumber: string;
}
export const registerExternal = (model: RegisterExternalModel) =>
  Axios.post("/api/frontend/registration/external", model, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
    },
  });
export interface ProfileDto {
  Email: string;
  Gender: string;
  LastName: string;
  FirstName: string;
  PhoneNumber: string;
  PostalCode: string;
  Street: string;
  City: string;
  CountryCode: string;
  PreferredLanguage: string;
  EmailConfirmed: boolean;
  isPasswordSet: boolean;
  canContact: boolean;
  AdacNumber: string;
}
export const getProfile = () =>
  Axios.get<any>("/api/frontend/profile", {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((resp) => {
    if (resp.data?.issue === 'Profile missing') {
      return 'Profile missing';
    }
    return profileMapper(resp.data);
  });
  
export interface UpdateProfileDto {
  Gender: string;
  LastName: string;
  FirstName: string;
  PhoneNumber: string;
  PostalCode: string;
  Street: string;
  City: string;
  CountryCode: string;
  PreferredLanguage: String;
}
export const updateProfile = (model: UpdateProfileDto) =>
  Axios.post("/api/frontend/profile/update", model, {
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
    },
  });

export const updateEmail = (newEmail: string) =>
  Axios.post(
    `/api/frontend/profile/ChangeEmail`,
    { email: newEmail },
    {
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
      },
    }
  );

  export const changeContactMe = (contactMe: boolean) =>
  Axios.post(
    "/api/frontend/profile/ChangeContactMe",
    { ContactMe: contactMe },
    {
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
      },
    }
  );

export const changePassword = (newPassword: string, currentPassword: string) =>
  Axios.post(
    `/api/frontend/profile/ChangePassword`,
    { newPassword, currentPassword },
    {
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
      },
    }
  );
export const logout = (logoutId: string | null) =>
  Axios.post(
    `/api/frontend/account/logout`,
    { LogoutId: logoutId },
    {
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
      },
    }
  );

export const checkAuth = () =>
  Axios.get(`/api/frontend/auth/check`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
export const externalLogin = (provider: string, returnUrl: string | null) => {
  if (returnUrl === null) {
    returnUrl = "/profile";
  }
  window.location.href = `api/frontend/external/challenge?provider=${provider}&returnUrl=${encodeURIComponent(
    returnUrl
  )}`;
};

export const initValidation = (email: string, password: string) =>
  Axios.post(
    `/api/frontend/registration/initValidate`,
    { email, password },
    {
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
      },
    }
  );
export const forgotPassword = (email: string,returnUrl?:string|null) =>
  Axios.post(
    `/api/frontend/account/forgotPassword`,
    { email,returnUrl },
    {
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
      },
    }
  );
export const validatePasswordReset = (email: string, token: string) =>
  Axios.post(
    `/api/frontend/account/validateToken`,
    { email, token},
    {
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
      },
    }
  );

export const resetPassword = (
  email: string,
  newPassword: string,
  confirmationToken: string,
  returnUrl:string|null
) =>
  Axios.post(
    `/api/frontend/account/ResetPassword`,
    { newPassword, email, confirmationToken,returnUrl},
    {
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
      },
    }
  );
export const getError = (errorId: string) =>
  Axios.get(`/api/frontend/error/error?errorId=${errorId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

export const resendConfirmationEmail = () =>
  Axios.post(`/api/frontend/profile/ResendConfirmationEmail`, {
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-TOKEN": getCookie("X-XSRF-TOKEN"),
    },
  });

export const refreshAntiforgery = () =>
  Axios.get(`/api/frontend/auth/refresh`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  export const getExternalClaims = () =>
  Axios.get(`/api/frontend/external/claims`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
