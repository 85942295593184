import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { CardContent, CardActions, Card, CardHeader, Button, } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { SimpleInput } from "./SimpleInput";
import { useForm } from "react-hook-form";
import { forgotPassword } from "../utils/accountAPI";
import { useHistory } from "react-router-dom";
import { sharedStyles } from "../themes/sharedStyles";
import { BwtButton, BwtButtons } from "./BwtButton";
import { ErrorCard } from "./ErrorCard";
import { useLoginContext } from "../Providers/loginProvider";
import { useUrlQuery } from "../utils/useUrlQuery";
import { getReturnUrl } from "../utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    container: {
      display: "flex",
      flexWrap: "wrap",
      margin: `${theme.spacing(0)} auto`,
      justifyContent: "center",
    },
    resetButton: {
      marginTop: theme.spacing(1),
      flexGrow: 1,
      borderRadius: "50px",
      height: "50px",
    },
    capitalizeButton: {
      textTransform: "capitalize",
      boxShadow: "none",
      height: "50px",
      maxWidth: "320px",
      minWidth: "200px",
      marginTop: "16px",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    cardHeader: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "1.67",
      textAlign: "left",
      color: "var(--col-navy-500)",
    },
    headerRoot: {
      paddingBottom: "0px",
    },
    centeredContainer: {
      width: theme.spacing(40),
      padding: "8px 16px",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    textContent: {
      margin: "8px 0px",
    },
  })
);
interface IForgottenPasswordBodyProps {
  setEmailSent: () => void;
}
export const ForgottenPasswordBody: React.FC<IForgottenPasswordBodyProps> = (
  props
) => {
  const classes = useStyles();
  const [localState, setState] = useState({ error: false, submitting: false });
  const history = useHistory();
  const query =useUrlQuery();
  const { state } = useLoginContext();
  const { handleSubmit, control, errors } = useForm({});
  const handleSendEmail = (data: Record<string, any>) => {
    setState({ error: false, submitting: true });
    const returnUrl = query.get("ReturnUrl") ||  localStorage.getItem('returnUrl');
    forgotPassword(data["email"],returnUrl)
      .then(
        (res: any) => {
          if (res?.data?.errorCode === "1200") {
            setState({ error: true, submitting: false });
            return;
          }
          if (res?.data?.errorCode === "1201") {
            setState({ error: true, submitting: false });
            return;
          }
          if (res?.data?.errorCode === "1102") {
            history.push("/error?code=FPB-003");
            return;
          }
          props.setEmailSent();
        },
        () => {
          history.push("/error?code=FPB-001");
        }
      )
      .catch(() => {
        history.push("/error?code=FPB-002");
      });
  };

  const onCancel = () => {
    history.push("/");
  };
  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div>
            {localState.error && (
              <ErrorCard
                text={"forgotPassword.emailNotFound"}
                header={"forgotPassword.sorry"}
              ></ErrorCard>
            )}
            <form
              className={classes.container}
              autoComplete="on"
              onSubmit={handleSubmit((data) =>
                handleSendEmail(data)
              )}
            >
              <Card className={classes.card}>
                <CardHeader
                  classes={{
                    title: classes.cardHeader,
                    root: classes.headerRoot,
                  }}
                  title={translate("forgotPassword.forgotPassword")}
                />
                <div className={classes.centeredContainer}>
                  <p className={classes.textContent}>
                    {translate("forgotPassword.toResetYourPass")}
                  </p>
                </div>
                <CardContent style={{ padding: "8px 16px" }}>
                  <div>
                    <SimpleInput
                      autocomplete="email"
                      id={"email"}
                      type="email"
                      label={translate("login.email") as string}
                      control={control}
                      required={true}
                      errors={errors}
                      defaultValue={state.email}
                    ></SimpleInput>
                  </div>
                </CardContent>
                <CardActions style={{ padding: "0px 16px", display: "grid" }}>
                  <BwtButton
                    styleType={BwtButtons.pink}
                    type="submit"
                    label={translate("forgotPassword.resetPassword").toString()}
                    disabled={localState.submitting}
                  ></BwtButton>
                  <Button
                    style={{ marginLeft: "0px" }}
                    onClick={() => onCancel()}
                    classes={{ root: classes.capitalizeButton }}
                  >
                    {translate("login.cancel")}
                  </Button>
                </CardActions>
              </Card>
            </form>
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
