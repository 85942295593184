import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import {
  Controller,
  Control,
  NestDataObject,
  FieldError,
  EventFunction,
} from "react-hook-form";
import { Translate, TranslateFunction } from "react-localize-redux";

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    input: {
      width:theme.spacing(40),
      border: "solid 1px var(--col-navy-500)",
      marginBottom: "20px",
      height: "48px",
      "& .MuiFormLabel-root.Mui-focused": {
        color: "var(--col-navy-500)",
      },
      "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
        transform: "translate(12px, 1.2px) scale(0.75)",
      },
      [theme.breakpoints.only('xs')]: {
        width:"100%",
      },
    },
    shortInput: {
      flexGrow: 1,
      width: theme.spacing(32.125)
    },
    inputFocus:{
      border : "solid 1px",
      borderColor: "var(--col-aidu-500)",
      boxShadow: "0 0 5px 0 var(--col-aidu-500)",
    },
    root: {
      height:"99%",
      backgroundColor: "white !important",
    },
    underline: {
      backgroundColor: "white",
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    label: {
      fontSize: "16px",
      lineHeight: "1.5",
      letterSpacing: "0.4px",
      color: "var(--col-navy-500)",
    },
    errorLabel: {
      color: "var(--col-accent-magnesium-500)",
      fontSize: "12px",
      display: "flex",
    },
    errorInput: {
      boxShadow: "0 0 5px 0 var(--col-accent-magnesium-500)",
      border: "solid 1px var(--col-accent-magnesium-500)"
    },
    formControl:{
      alignSelf: "center",
      justifySelf: "center",
      height: "98%",
      padding: "1px",
      width: "98%",
    }
  })
);
interface SimpleInputProps {
  errors?: NestDataObject<Record<string, any>, FieldError>;
  id: string;
  type: string;
  label: string;
  placeHolder?: string;
  value?: string;
  control?: Control;
  required?: boolean;
  onchange?: EventFunction;
  regexValidation?: RegExp;
  defaultValue?:string;
  autoFocus?:boolean;
  short?:boolean;
  autocomplete?:string;
}

export const SimpleInput: React.FC<SimpleInputProps> = ({
  errors,
  id,
  type,
  label,
  onchange,
  placeHolder,
  control,
  regexValidation,
  defaultValue ="",
  required = false,
  autoFocus = false,
  short = false,
  autocomplete="off"
}) => {

  const [state, setState] = useState({
    focused: false
  });
  const classes = useStyles();

  const getErrorMsg = (translate: TranslateFunction) => {
    if (errors?.[id]?.message) {
      return errors?.[id]?.message;
    }else if(errors?.[id]?.type === "pattern")
    return getInvalidPaternMsg(id,translate)
     else {
      return translate("validation.required");
    }
  };
  const getInvalidPaternMsg=(id :string,translate: TranslateFunction) =>{
    switch (id) {
      case "postcode":
        return translate("validation.invalidPostcode")
      case "adacNumber":
        return translate("validation.invalidAdacAccountNumber")
      default:
        return translate("validation.invalidInput")
    }
  }

  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div className={`${classes.input} ${short ? classes.shortInput : ""} ${errors?.[id] ? classes.errorInput : "" } ${state.focused ? classes.inputFocus : ""}  `}  >
            <Controller
            autoFocus={autoFocus}
            className={classes.formControl}
              variant="filled"
              InputProps={{
                classes: { underline: classes.underline, root: classes.root },
              }}
              InputLabelProps={{ classes: { filled: classes.label } }}
              id={id}
              type={type}
              label={label}
              placeholder={placeHolder}
              rules={{
                required: required,
                pattern: regexValidation,
              }}
              as={TextField}
              name={id}
              control={control}
              onChange={onchange}
              defaultValue={defaultValue}
              onFocusCapture={()=>
                setState({focused:true})
              }
              onBlurCapture={()=>
                setState({focused:false})
              }
              autoComplete={autocomplete}
              
            />
            {errors?.[id] && (
              <span className={classes.errorLabel}>
                {getErrorMsg(translate)}
              </span>
            )}
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
