import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Translate, TranslateFunction } from "react-localize-redux";
import { useForm } from "react-hook-form";
import { MaskedInput } from "./MaskedInput";
import { Theme } from "@material-ui/core";
import { BwtButton, BwtButtons } from "./BwtButton";
import { changePassword, refreshAntiforgery } from "../utils/accountAPI";
import { AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";
import { containsLetter, containsNumber, atLeastXLong, containsSpecialCharecter } from "../utils/regexp";
import { useAuthContext } from "../Providers/authProvider";
import { useLoginContext } from "../Providers/loginProvider";
import { PasswordDescription } from "./PasswordDescription";
import { getErrors } from "../utils/errors";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      fontSize: "18px",
      textAlign: "left",
      fontWeight: "bold",
      lineHeight: "1.67",
      color: "var(--col-navy-500)",
    },
    body: {
      display: "flex",
      justifyContent: "center",
    },
    container: {
      padding: theme.spacing(5),
      [theme.breakpoints.only("xs")]: {
        paddingTop: theme.spacing(4),
        paddingBottom: "12px",
        width: "100%",
      },
    },
    errorLabel: {
      color: "var(--col-accent-magnesium-500)",
      fontSize: "12px",
      display: "flex",
    },
    descriptionContainer: {
      width: "20vw",
      minWidth: "200px",
      marginBottom: "15px",
    },
    descriptionText: {
      fontSize: "14px",
      lineHeight: "1.29",
      letterSpacing: "0.35px",
    },
  });
});

interface IChangePasswordProps {
  isPasswordSet: boolean;
  setPasswordSet: () => void;
}
interface IChangePasswordState {
  disableButton: boolean;
}
export const ChangePassword: React.FC<IChangePasswordProps> = ({
  isPasswordSet,
  setPasswordSet,
}) => {
  const loginContext = useLoginContext();
  const classes = useStyles();
  const history = useHistory();
  const { setAuthState } = useAuthContext();
  const [state, setState] = useState<IChangePasswordState>({
    disableButton: false,
  });
  const { handleSubmit, control, errors, watch, setError } = useForm({});
  useEffect(() => {
    control.triggerValidation("newPassword");
  }, [control]);
  const passErrors = getErrors(errors["newPassword"]);
  const submitChangePassword = (
    data: Record<string, any>,
    translate: TranslateFunction
  ) => {
    setState({ disableButton: true });
    const currentPassword = isPasswordSet ? data["currentPassword"] : null;
    changePassword(data["newPassword"], currentPassword)
      .then(
        (res: any) => handleResponse(res),
        (rej: any) => {
          if (rej.response.data.errors) {
            handleServerSideValidationError(
              rej.response.data.errors,
              translate
            );
            setState({ disableButton: false });
            return;
          }
          if (rej.response.status === 401) {
            setAuthState({ isAuth: false });
            refreshAntiforgery();
            history.push("/login", { email: loginContext.state.email });
            return;
          }
          history.push("/error?code=CP-001");
        }
      )
      .catch(() => {
        history.push("/error?code=CP-002");
      });
  };
  const handleServerSideValidationError = (
    errors: any,
    translate: TranslateFunction
  ) => {
    if (errors.CurrentPassword) {
      setError(
        "currentPassword",
        "serverSide",
        translate("profile.invalidPassword").toString()
      );
    }
    if (errors.NewPassword) {
      setError(
        "newPassword",
        "serverSide",
        translate("profile.invalidPassword").toString()
      );
    }
  };
  const handleResponse = (res: AxiosResponse<any>) => {
    if (!res.data.passwordChanged) {
      setState({ disableButton: false });
      if (res.data.errorCode === "1001") {
        setError("newPassword", "serverSideError");
        return;
      }
      if (res.data.errorCode === "1102") {
        history.push("/error?code=CP-003");
        return;
      }
      setError("currentPassword", "serverSideError", res.data.validationError);
      return;
    }
    setPasswordSet();
    history.goBack();
  };
  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div className={classes.body}>
            <div className={classes.container}>
              <form
                onSubmit={handleSubmit((data) =>
                  submitChangePassword(data, translate)
                )}
              >
                <span className={classes.title}>
                  {isPasswordSet
                    ? translate("profile.changePassword")
                    : translate("profile.setPassword")}
                </span>
                <div style={{ paddingTop: "30px" }}>
                  {isPasswordSet && (
                    <MaskedInput
                      value={watch("currentPassword", "")}
                      id="currentPassword"
                      label={translate("profile.currentPassword") as string}
                      placeHolder={
                        translate("profile.currentPassword") as string
                      }
                      required={true}
                      errors={errors}
                      control={control}
                      allowShow={false}
                    ></MaskedInput>
                  )}
                  <MaskedInput
                    onchange={([selected]) => {
                      setTimeout(() => {
                        control.triggerValidation("newPassword", true);
                      }, 20);
                      return selected;
                    }}
                    id="newPassword"
                    label={translate("profile.newPassword") as string}
                    placeHolder={translate("profile.newPassword") as string}
                    value={watch("newPassword", "")}
                    allowShow={true}
                    control={control}
                    errors={errors}
                    required={true}
                    showErrorMsg={false}
                    showErrorBorder={false}
                    regexValidation={[
                      containsLetter,
                      containsNumber,
                      atLeastXLong,
                      containsSpecialCharecter
                    ]}
                  ></MaskedInput>
                  <PasswordDescription
                    passErrors={passErrors}
                    control={control}
                  ></PasswordDescription>
                </div>
                <BwtButton
                  label={translate("profile.saveChanges").toString()}
                  styleType={BwtButtons.pink}
                  type="submit"
                  disabled={state.disableButton}
                ></BwtButton>
              </form>
            </div>
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
