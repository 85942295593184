import React from "react";

const BwtUnticked = ({ color = "var(--col-navy-500)" }) => (
  <svg
    id="controls_toggle_circle_unchecked"
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <g
      id="Ellipse_1"
      data-name="Ellipse 1"
      fill="#fff"
      stroke={color}
      strokeWidth="1.33"
    >
      <circle cx="8.5" cy="8.5" r="8.5" stroke="none" />
      <circle cx="8.5" cy="8.5" r="7.835" fill="none" />
    </g>
  </svg>
);

export default BwtUnticked;
