export const languagesWithDocs = ["en", "de", "it", "fr"];

export const getLinkToPrivacyPolicy = (langCode: string) => {
  if (languagesWithDocs.includes(langCode.toLowerCase())) {
    return `https://www.bwt.com/${langCode.toLowerCase()}/data-privacy`;
  }
  return "https://www.bwt.com/en/data-privacy";
};

export const getLinkToCookiesPolicy = (langCode: string) => {
  if (languagesWithDocs.includes(langCode.toLowerCase())) {
    return `https://www.bwt.com/${langCode.toLowerCase()}/cookies`;
  }
  return "https://www.bwt.com/en/cookies";
};

export const onBackToHomepage = () => {
  window.location.href = "https://bwt.com";
};

export const onBackToRedirect = (redirect: string) => {
    window.location.href =  redirect;
};


