import React from "react";
import { Translate } from "react-localize-redux";
import { useUrlQuery } from "../utils/useUrlQuery";
import { makeStyles, createStyles, Theme, Button } from "@material-ui/core";
import { AvatarMenu } from "../components/AvatarMenu";
import { MiniLogo } from "../components/MiniLogo";
import { BwtButton, BwtButtons } from "../components/BwtButton";
import { onBackToHomepage, onBackToRedirect } from "../utils/linkToDocs";
import { useHistory } from "react-router-dom";
import { getUserFrendlyLabelForUrl } from "../utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: "var(--col-navy-500)",
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "1.5",
    },
    titleError: {
      color: "var(--col-navy-500)",
      fontSize: "2.0em",
      lineHeight: "1.2em",
      fontWeight: "bold",
    },
    centeredGrid: {
      display: "grid",
      justifyContent: "center",
      textAlign: "center",
    },
    body: {
      color: "var(--col-navy-500)",
      fontSize: "16px",
      lineHeight: "1.2em",
    },
    container: {
      paddingTop: theme.spacing(16),
    },
    bodyPadding: {
      margin: "16px",
      paddingTop: theme.spacing(5),
    },
    capitalizeButton: {
      textTransform: "capitalize",
      boxShadow: "none",
      height: "50px",
      width: "320px",
      marginTop: "16px",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
  })
);

export const EmailConfirmPage: React.FC = () => {
  const classes = useStyles();
  const urlParams = useUrlQuery();
  const history = useHistory();

  const onBackToAccount = () => {
    history.push("/");
  };
  return (
    <React.Fragment>
      <div>
        <AvatarMenu></AvatarMenu>
        <MiniLogo></MiniLogo>
      </div>
      <Translate>
        {({ translate }) => (
          <div className={classes.container}>
            <div className={classes.centeredGrid}>
              <div className={classes.title}>
                {translate("profile.myAccount")}
              </div>
              {urlParams.get("Confirmed") === "true" && (
                <div className={classes.bodyPadding}>
                  <div className={classes.body}>
                    {translate("emailConfirmation.thankYou")}
                  </div>
                  <div className={classes.body}>
                    {translate("emailConfirmation.emailVerified")}
                  </div>
                </div>
              )}
              {urlParams.get("Confirmed") === "false" && (
                <div>
                  <div className={classes.titleError}>
                    {translate("emailConfirmation.failedVerification")}
                  </div>
                  <div className={classes.titleError}>
                    {translate("emailConfirmation.pleaseTryAgain")}
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "grid",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {urlParams.get("Redirect") == null && (
                <div style={{ margin: "auto" }}>
                  <BwtButton
                    styleType={BwtButtons.pink}
                    type="button"
                    onClick={() => onBackToHomepage()}
                    label={translate("resendEmail.backToHomepage").toString()}
                  ></BwtButton>
                </div>
              )}

              {urlParams.get("Redirect") !== null && (
                <div style={{ margin: "auto" }}>
                <BwtButton
                    styleType={BwtButtons.pink}
                    type="button"
                    onClick={() =>
                        onBackToRedirect(urlParams.get("Redirect") ?? "")
                    }
                    label={
                        translate("resendEmail.backTo").toString() +
                        getUserFrendlyLabelForUrl(urlParams.get("Redirect"))
                }
                  ></BwtButton>
                </div>
              )}

              <Button
                style={{ marginLeft: "0px", width: "340px" }}
                classes={{ root: classes.capitalizeButton }}
                onClick={() => onBackToAccount()}
              >
                {translate("resendEmail.backToAccount")}
              </Button>
            </div>
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
