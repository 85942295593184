import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  CardContent,
  CardActions,
  Card,
  CardHeader,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { useForm } from "react-hook-form";
import { MaskedInput } from "../components/MaskedInput";
import {
  containsLetter,
  containsNumber,
  atLeastXLong,
  containsSpecialCharecter,
} from "../utils/regexp";
import { useUrlQuery } from "../utils/useUrlQuery";
import { validatePasswordReset, resetPassword } from "../utils/accountAPI";
import { LinkNotValid } from "../components/LinkNotValid";
import { useHistory } from "react-router-dom";
import { sharedStyles } from "../themes/sharedStyles";
import { BwtButton, BwtButtons } from "./BwtButton";
import { useAuthContext } from "../Providers/authProvider";
import { PasswordDescription } from "./PasswordDescription";
import { getErrors } from "../utils/errors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    container: {
      display: "flex",
      flexWrap: "wrap",
      margin: `${theme.spacing(0)} auto`,
      justifyContent: "center",
    },
    resetButton: {
      marginTop: theme.spacing(1),
      flexGrow: 1,
      borderRadius: "50px",
      height: "50px",
    },
    cardHeader: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "1.67",
      textAlign: "left",
      color: "#5f6062",
    },
    headerRoot: {
      paddingBottom: "16px",
    },
    descriptionContainer: {
      width: theme.spacing(40),
      marginBottom: "15px",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    descriptionText: {
      fontSize: "14px",
      lineHeight: "1.29",
      letterSpacing: "0.35px",
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "40px",
    },
    capitalizeButton: {
      textTransform: "capitalize",
      boxShadow: "none",
      height: "50px",
      width: "320px",
      marginTop: "16px",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
  })
);
interface ConfirmPasswordResetState {
  email: string | null;
  token: string | null;
  error: boolean;
  loading: boolean;
  submitting: boolean;
  returnUrl: string |null;
}
const initState: ConfirmPasswordResetState = {
  email: "",
  token: "",
  error: false,
  loading: true,
  submitting: false,
  returnUrl: ""
};

export const ConfirmPasswordReset: React.FC = () => {
  const classes = useStyles();
  const [state, setState] = useState<ConfirmPasswordResetState>(initState);

  const queryParams = useUrlQuery();
  const email = queryParams.get("email");
  const token = queryParams.get("confirmationToken");
  const returnUrl = queryParams.get("returnUrl");

  const history = useHistory();

  const { setAuthState } = useAuthContext();
  const { handleSubmit, control, errors, watch } = useForm({});

  useEffect(() => {
    setState({ ...state,returnUrl, email, token, loading: true });
    if (email && token ) {
      validatePasswordReset(email, token).then(
        (res: any) => {
          const errorCode = res?.data?.errorCode;
          if (errorCode) {
            setState((oldState) => ({
              ...oldState,
              error: true,
              loading: false,
            }));
            return;
          }
          setState({ ...state,returnUrl, email, token, loading: false });
          control.triggerValidation("newPassword", true);
        },
        (rej: any) =>
          setState((oldState) => ({ ...oldState, error: true, loading: false }))
      );
    } else {
      setState((oldState) => ({ ...oldState, error: true, loading: false }));
    }
    /* eslint-disable-next-line */
  }, []);

  const passErrors = getErrors(errors["newPassword"]);

  const handlePasswordReset = (data: Record<string, any>) => {
    if (state.email && state.token) {
      setState({ ...state, submitting: true });
      resetPassword(state.email, data["newPassword"], state.token,  state.returnUrl)
        .then((res: any) => {
          const redirectUrl = res?.data?.redirectUrl;
          const errorCode = res?.data?.errorCode;
          if (redirectUrl) {
            setAuthState({ isAuth: true });
            window.location.href = redirectUrl;
            localStorage.removeItem("returnUrl")
          }
          if (errorCode) {
            setState((oldState) => ({
              ...oldState,
              errors: true,
              loading: false,
            }));
          }
        })
        .finally(() => {
          setState({ ...state, submitting: false });
        });
    }
  };
  const onCancel = () => {
    history.push("/");
  };
  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <div>
            {state.loading && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}
            {state.error && !state.loading && <LinkNotValid></LinkNotValid>}
            {!state.error && !state.loading && (
              <form
                className={classes.container}
                autoComplete="on"
                onSubmit={handleSubmit((data) => handlePasswordReset(data))}
              >
                <Card className={classes.card}>
                  <CardHeader
                    classes={{
                      title: classes.cardHeader,
                      root: classes.headerRoot,
                    }}
                    title={translate("forgotPassword.changePassword")}
                  />
                  <CardContent style={{ padding: "8px 16px" }}>
                    <div>
                      <MaskedInput
                        onchange={([selected]) => {
                          setTimeout(() => {
                            control.triggerValidation("newPassword", true);
                          }, 20);
                          return selected;
                        }}
                        value={watch("newPassword", "")}
                        id="newPassword"
                        label={translate("profile.newPassword") as string}
                        required={true}
                        errors={errors}
                        control={control}
                        allowShow={true}
                        showErrorBorder={false}
                        showErrorMsg={false}
                        regexValidation={[
                          containsLetter,
                          containsNumber,
                          atLeastXLong,
                          containsSpecialCharecter,
                        ]}
                      ></MaskedInput>
                      <PasswordDescription
                        passErrors={passErrors}
                        control={control}
                      ></PasswordDescription>
                    </div>
                  </CardContent>
                  <CardActions style={{ padding: "0px 16px 10px" }}>
                    <div style={{ display: "grid", width: "100%" }}>
                      <BwtButton
                        label={translate(
                          "forgotPassword.changePassword"
                        ).toString()}
                        type="submit"
                        styleType={BwtButtons.pink}
                        disabled={state.submitting}
                      ></BwtButton>
                      <Button
                        style={{ marginLeft: "0px" }}
                        classes={{ root: classes.capitalizeButton }}
                        onClick={() => onCancel()}
                      >
                        {translate("login.cancel")}
                      </Button>
                    </div>
                  </CardActions>
                </Card>
              </form>
            )}
          </div>
        )}
      </Translate>
    </React.Fragment>
  );
};
